import React, { createContext, useState } from 'react';

export const ProfileContext = createContext();

const ProfileProvider = ({ children }) => {

    const [profile, setProfile] = useState({ user: null });

    const saveProfile = newProfile => setProfile(newProfile);

    return (
        <ProfileContext.Provider value={{ profile, saveProfile }}>
            { children }
        </ProfileContext.Provider>
    )

}

export default ProfileProvider;