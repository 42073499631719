import './style.scss';

const Modal = ({ children, state, setState }) => {

    return(
        
        <dialog className={ !state ? 'modal': 'modal open' }>

            <div className="grid">

                <div className="content">

                    <button className="content__button content__button--close" onClick={ () => setState(!state) }>X Fechar</button>

                    { children }
                    

                </div>

            </div>

        </dialog>        

    );

};

export default Modal;