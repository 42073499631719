import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import Card from './Card';

import useLoading from '../../hooks/useLoading';

import { ModuleContext } from '../../contexts/Module';

import { requestApi } from '../../services/Request';
import { showDialogError, showDialogToast } from '../../helpers/Utils';

import './styles.scss';
import { getToken } from '../../services/Auth';

const EditSortableCategories = () => {

    const moduleContext = useContext(ModuleContext);
    const { module } = moduleContext;

    const [loader, showLoader, hideLoader] = useLoading();

    const [categories, setCategories] = useState([]);

    const token = getToken();

    useEffect(() => {
        
        setCategories(module.categories);

    }, [moduleContext.module]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {

        const dragCard = categories[dragIndex];

        setCategories(update(categories, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard],
            ],
        }));

    }, [categories]);

    const submitForm = () => {

        showLoader(true);
        
        const idCategories = categories.map(category => (category.id));
        const data = { categories: idCategories };
        
        requestApi('/api/categories/order', 'POST', 'json', token, data, '', '')
            .then(response => {
                
                moduleContext.handleToogleEditSortable(false);
                showDialogToast('success', response.message);
                moduleContext.saveUpdateModule();

            })
            .catch(error => {

                moduleContext.handleToogleEditSortable(false);
                showDialogError('error', error.message);
                hideLoader(true);

            })

    };

    return(
        <Fragment>
            
            <section className="sortableCategories">

                <div className="grid">

                    <div className="title">

                        <h2 className="title__text">Ordernação de categorias</h2>

                        <div className="title__actions">

                            <button 
                                className="title__button"
                                onClick={ () => moduleContext.handleToogleEditSortable(false) }>                                                
                                    [Descartar alteração]
                            </button>


                        </div>

                    </div>


                    <DndProvider backend={ HTML5Backend }>

                        <div className="list">

                            {

                                categories.map((category, index) => (

                                    <Card
                                        key={ category.id }
                                        index={ index}
                                        id={ category.id }
                                        categoryName={ category.name }
                                        moveCard={ moveCard }
                                    />

                                ))

                            }

                        </div>

                        <button
                            className="sortable__button"
                            type="button"
                            onClick={ () => submitForm() }>
                            Salvar Ordem
                        </button>

                    </DndProvider>


                </div>

            </section>

            { loader }

        </Fragment>
    )

};

export default EditSortableCategories;