import { useState, useEffect, useContext, Fragment, useRef } from 'react';
import { useForm } from 'react-hook-form';

import useLoading from './../../hooks/useLoading';

import Modal from './../Modal';
import Form from './../Form';

import { getToken } from './../../services/Auth';
import { ProfileContext } from './../../contexts/Profile';
import { requestApi } from './../../services/Request/index';
import { showDialogToast, showDialogError, prepareFormData } from './../../helpers/Utils';
import { InputText, InputFileImage, InputTextMask, Select } from './../Fields';

const EditProfile = ({ state, setState }) => {

    const form = useRef();
    const token = getToken();
    const { register, control, handleSubmit, errors, reset } = useForm();
    const [loader, showLoader, hideLoader] = useLoading();

    const profileContext = useContext(ProfileContext);
    const { avatar, name, email, phone, manager, bank_account } = profileContext.profile;
    
    const [managers, setManagers] = useState([]);
    const [banks, setBanks] = useState([]);
    const [managerCurrent, setManagerCurrent] = useState(false);
    const [changeAvatar, seChangeAvatar] = useState(false);
    

    useEffect(() => {

        if(managers.length === 0 && banks.length === 0) {
            
            // managers
            requestApi('/api/managers', 'GET', 'json', '', '')
                .then(response => setManagers(response.content.data))
                .catch(error => showDialogError('error', error.message));
    
            // banks
            requestApi('/api/banks', 'GET', 'json', '', '')
                .then(response => setBanks(response.content.data))
                .catch(error => showDialogError('error', error.message));

        }


        if(managers.length > 0 && banks.length > 0) {

            reset({ 
                name, 
                email, 
                phone, 
                manager_id: manager?.id,
                "bank_account[bank_id]": bank_account?.bank_id,
                "bank_account[agency]": bank_account?.agency,
                "bank_account[account]": bank_account?.account,
                "bank_account[document]": bank_account?.document,
            });
            
        }


    }, [reset, name, email, phone, manager?.id, bank_account, managers, banks]);

    const submitForm = data => {

        showLoader(true);

        const formData = new FormData(form.current);
        let addsFormData = [{ name: '_method', value: 'PUT' }];

        if(!managerCurrent) {

            const newsAddsFormData = [
                { name: 'bank_account[agency]', value: ''},
                { name: 'bank_account[bank_id]', value: ''},
                { name: 'bank_account[account]', value: ''},
                { name: 'bank_account[document]', value: ''},
            ];

            addsFormData = [...addsFormData, ...newsAddsFormData];
        }

        const newFormData = prepareFormData(formData, addsFormData, []);

        requestApi('/api/users/me', 'POST', 'formData', token, newFormData, '')
            .then(response => {

                hideLoader(false);
                setState(false);
                showDialogToast('success', response.message);
                updateProfile();

            })
            .catch(error => {
                
                hideLoader(false);
                showDialogError('error', error.message);
            
            });
        

    };

    const updateProfile = () => {

        requestApi('/api/users/me', 'GET', 'json', token, '', '')
            .then(response => profileContext.saveProfile(response.content))
            .catch(error => showDialogError('error', error.message));

    }

    const changeManagers = event => {
        const managerFound = managers.find(element => element.id === parseInt(event.target.value));
        managerFound && managerFound.work_on_field ? setManagerCurrent(true) : setManagerCurrent(false);    
    };

    return(

        <Fragment>

            <Modal state={ state } setState={ setState }>
            
            <Form onSubmit={ handleSubmit(submitForm) } foRef={ form } encType="multipart/form-data">               

                {
                    changeAvatar ?

                    <InputFileImage
                        label=""
                        name="avatar"
                        inputRef={ register() }
                        />

                    :

                    <figure className="image-default">
                        <img className="image-default__image" src={`${process.env.REACT_APP_DOMAIN}/storage/${avatar}`} alt={ name } />
                        <button className="image-default__button" onClick={ () => seChangeAvatar(true) }>Alterar Imagem</button>
                    </figure>

                }

                <InputText
                    label="Nome completo"
                    type="text"
                    name="name"
                    inputRef={ register({ required: true }) }
                    error={ errors.name }
                    filled="true"
                    />

                <Select
                    label="Escolha gerente"
                    name="manager_id"
                    inputRef={ register({ required: true}) }
                    error={ errors.manager_id }
                    options={ managers }
                    valueDefault="id"
                    onChange={ event => changeManagers(event)}
                    />

                {
                    managerCurrent &&
               
                        <Fragment>
                            <div className="form__row form__row--three">
                                
                                <Select 
                                    label="Escolha o banco"
                                    name="bank_account[bank_id]"
                                    inputRef={ register( { required: true }) }
                                    error={ errors?.bank_account?.bank_id }
                                    options={ banks }
                                    valueDefault="id"
                                    />

                                <InputText
                                    label="Agência"
                                    type="text"
                                    name="bank_account[agency]"
                                    inputRef={ register({ required: true })}
                                    error={ errors?.bank_account?.agency }
                                    filled="true"
                                    />

                                <InputText
                                    label="Nº da conta"
                                    type="text"
                                    name="bank_account[account]"
                                    inputRef={ register({ required: true })}
                                    error={ errors?.bank_account?.account }
                                    filled="true"
                                    />

                            </div>

                            <InputTextMask
                                label="Documento CPF"
                                name="bank_account[document]"
                                inputRef={{ required: true }}
                                error={ errors?.bank_account?.document }
                                control={ control }
                                mask="999.999.999-99"
                                defaultValue=""
                                filled="true"
                                />

                            

                        </Fragment>
                    
                }

                <InputTextMask
                    label="Celular corporativo"
                    name="phone"
                    inputRef={{ required: true }}
                    error={ errors?.bank_account?.document }
                    control={ control }
                    mask="(99) 99999-9999"
                    defaultValue=""
                    filled="true"
                    />

                <InputText
                    label=""
                    type="hidden"
                    name="email"
                    inputRef={ register() }
                    error={ errors.email }
                    value={ email }
                    />
                <button className="form__button" type="submit">Enviar</button>

            </Form>

        </Modal>

        { loader }

        </Fragment>
                
    );

};

export default EditProfile;