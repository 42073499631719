import { useContext, useEffect } from 'react';
import { BrowserRouter, Switch, Route, useHistory, Redirect } from 'react-router-dom';

// Pages
import Home from './../../pages/Home';
import Module from './../../pages/Module';
import Category from './../../pages/Category';
import Company from './../../pages/Company';
import Login from './../../pages/Login';
import Register from './../../pages/Register';
import RecoverPassword from './../../pages/RecoverPassword';
import RedefinePassword from './../../pages/RedefinePassword';
import NotFound from './../../pages/NotFound';

// Functions
import { ProfileContext } from './../../contexts/Profile';
import { getToken, isAuthenticated, removeToken } from '../Auth';
import { requestApi } from '../Request';

const Routes = () => {

    return(

        <BrowserRouter>
            <Switch>
                
                <RoutePrivate component={ Home } path="/" exact/>
                
                <RoutePrivate component={ Module } path="/modulo/:module" exact />

                <RoutePrivate component={ Category } path="/modulo/:module/categoria/:category" exact />

                <RoutePrivate component={ Company } path="/modulo/:module/empresa/:company" exact />

                <RoutePublic component={ Login } path="/login" exact />

                <RoutePublic component={ Register } path="/cadastro" exact />

                <RoutePublic component={ RecoverPassword } path="/recuperar-senha" exact />

                <RoutePublic component={ RedefinePassword } path="/redefinir-senha" exact />

                <Route component={ NotFound } />

            </Switch>
        </BrowserRouter>

    )

};

const RoutePublic = ({ component: Component, ...rest }) => {

    return(

        <Route { ...rest } render={ props => !isAuthenticated() ? (<Component { ...props } />) : <Redirect to="/" /> } />

    )

};

const RoutePrivate = ({ component: Component, ...rest }) => {

    const history = useHistory();
    const profileContext = useContext(ProfileContext);
    const token = getToken();

    useEffect(() => {

        requestApi('/api/users/me', 'GET', 'json', token, '', '')
            .then(response => profileContext.saveProfile(response.content))
            .catch(error => {

                removeToken();
                history.push('/login');

            })

    }, [history, token]);

    return(

        <Route { ...rest } render={ props => isAuthenticated() ? (<Component { ...props } />) : <Redirect to="/login" /> } />

    )

};

export default Routes;