import CompanyProvider from './../../contexts/Company';

import PrivateArea from './../../components/PrivateArea';
import MenuCategoriesSpecial from '../../components/MenuCategories/Special';
import AsideFilter from './../../components/AsideFilter';
import ResultsCompany from '../../components/ResultsCompany';

import './styles.scss';

const Company = () => {

    return(

        <PrivateArea>
            
            <CompanyProvider>

                <MenuCategoriesSpecial/>

                <main className="companies">

                    <div className="grid">

                        <AsideFilter/>

                        <ResultsCompany />

                    </div>

                </main>

            </CompanyProvider>
        
        </PrivateArea>

    )

};

export default Company;