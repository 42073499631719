import Company from '../../components/Company';
import ListModules from '../../components/ListModules';
import Summary from '../../components/Summary';
import PrivateArea from './../../components/PrivateArea';
import Blog from './../../components/Blog';
import Terms from '../../components/Terms';

const Home = () => {

    return(

        <PrivateArea>

            <main className="home">

                <Summary />

                <ListModules />

                <Company />
                
                <Blog />

                <Terms />

            </main>
            

        </PrivateArea>
        
    );

};

export default Home;