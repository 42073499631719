import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import ProfileProvider from './contexts/Profile';
import Routes from "./services/Routes";


const App = () => {

    return (

        <ProfileProvider>

            <Routes />

            <ToastContainer />

        </ProfileProvider>
        
    )

};

export default App;