import { useEffect } from 'react';
import { useParams } from 'react-router';

import { useCompany } from '../../contexts/Company';

import { showDialogError } from '../../helpers/Utils';
import { getToken } from '../../services/Auth';
import { requestApi } from './../../services/Request';

const Categories = () => {

    const { module, company } = useParams();
    const token = getToken();
    
    const { activeCategory, categories, saveActiveCategory, saveCategories } = useCompany();

    useEffect(() => {

        requestApi('/api/categories', 'GET', 'json', token, '', `?module=${module}&company=${company}&has-files=true`)
            .then(response => saveCategories(response.content.data))
            .catch(error => showDialogError('error', error.message));
        

    }, [module]);

    const changeCategories = event => {

        if(activeCategory.slug === event.target.name) {
            saveActiveCategory({});
            return;
        }
                
        saveActiveCategory({
            slug: event.target.name,
            name: event.target.value
        });

    };

    const handleCategoriesActive = slug => {
        
        return activeCategory.slug === slug;

    }

    return (

        <section className="categories">

            <ul>

                { 
                
                    categories && 
                    categories.map(category => (

                        <li key={category.slug}>
                            <label htmlFor={ category.slug } className={handleCategoriesActive(category.slug) ? 'active' : ''}>

                                <input 
                                    type="checkbox" 
                                    name={category.slug} 
                                    id={category.slug}
                                    value={category.name} 
                                    onChange={ changeCategories }/>

                                { category.name }

                            </label>
                        </li>

                    ))

                }

            </ul>

        </section>

    )

};

export default Categories;
