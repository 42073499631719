import Header from "./../Header";
import Footer from './../Footer';

import './style.scss';
import { useEffect } from "react";

const PrivateArea = (props) => {

    const { children } = props;

    useEffect(() => {

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })

    }, []);

    return(
        
        <div className="private">

            <Header />

            { children }                

            <Footer />

        </div>

    );

};

export default PrivateArea;