import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ModuleContext } from '../../../contexts/Module';

import { showDialogError } from '../../../helpers/Utils';
import { getToken } from '../../../services/Auth';
import { requestApi } from '../../../services/Request';

import './styles.scss';

const ListCompanies = () => {

    const moduleContext = useContext(ModuleContext);
    const { module } = moduleContext;
    const token = getToken(); 

    const [listCompanies, setListCompanies] = useState([]);

    const baseUrlResource = `${process.env.REACT_APP_DOMAIN}/storage`;

    useEffect(() => {

        requestApi('/api/companies', 'GET', 'json', token, '', `?module=${module.slug}`)
            .then(response => setListCompanies(response.content.data))
            .catch(error => showDialogError('error', error.message));

    }, [moduleContext.module]);

    return(

        <section className="listCompanies">

            <div className="grid">

                <h2 className="listCompanies__title">
                    Materiais de { module.title }
                    <small>Selecione a empresa</small>
                </h2>

                <ul className="listCompanies__list">
                    
                    
                    {
                        listCompanies &&
                        listCompanies.map(company => (

                            <li className="listCompanies__item" key={ company.slug }>

                                <Link to={`/modulo/${module.slug}/empresa/${company.slug}`} className="listCompanies__card">
                                    <img src={ `${baseUrlResource}/${company.logo}` } alt={ company.name } title={ company.name }/>
                                </Link>

                            </li>


                        ))
                    }

                </ul>

            </div>

        </section>

    )

};

export default ListCompanies;