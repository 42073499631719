import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { useCompany } from '../../contexts/Company';

import { requestApi } from '../../services/Request';
import { showDialogError } from '../../helpers/Utils';
import { getToken } from '../../services/Auth';

import './style.scss';

const MenuCategoriesSpecial = () => {

    const { module, company } = useParams();

    const token = getToken();

    const baseUrlResource = `${process.env.REACT_APP_DOMAIN}/storage`;

    const { categories, activeCategory, activeCompany, saveActiveCategory, saveActiveCompany } = useCompany();
    let categoriesOrderByASC = categories ? [...categories] : [];

    categoriesOrderByASC?.sort((a,b) => {
        return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
    });

    const [isOpenMenuCategories, setIsOpenMenuCategories] = useState(false);

    const [isWideVersion, setIsWideVersion] = useState(false);

    useEffect(() => {

        if(window.innerWidth >= 999) {
            setIsWideVersion(true);
        } else {
            setIsWideVersion(false);
        }

    }, []);

    useEffect(() => {

        requestApi('/api/companies', 'GET', 'json', token, '', `/${company}`)
            .then(response => saveActiveCompany(response.content))
            .catch(error => showDialogError('error', error.message))

    }, [module]);

    function handleOpenMenuCategories() {
        setIsOpenMenuCategories(!isOpenMenuCategories);
    };

    const changeCategories = category => {

        setIsOpenMenuCategories(false);
        
        if(activeCategory === category) {
            saveActiveCategory('');
            return;
        }

        saveActiveCategory(category);

    };

    return(
        
        <header className={ isOpenMenuCategories ? 'filter open' : 'filter'}>

            <div className="grid">

                {
                    isWideVersion ? (

                        <figure 
                            className="filter__logo filter__logo--clickable"
                            onClick={ handleOpenMenuCategories }
                        >
                            <img src={ `${baseUrlResource}/${activeCompany.logo}` } alt={ activeCompany.name }/>
                        </figure>

                    ) : (

                        <figure className="filter__logo">
                            <img src={ `${baseUrlResource}/${activeCompany.logo}` } alt={ activeCompany.name }/>
                        </figure>

                    )
                }                

                <Link 
                    className="filter__button filter__button--return" 
                    to={`/modulo/${module}`}>

                    <FontAwesomeIcon className="filter__icon" icon={ faChevronLeft } />
                    Voltar

                </Link>
                
            </div>

            <div className={ isOpenMenuCategories ? 'menu-categories open' : 'menu-categories'}>

                <div className="grid">

                    <ul className="list">

                        {

                            categoriesOrderByASC &&
                            categoriesOrderByASC.map(category => (

                                <li 
                                    className="item"
                                    key={ category.slug }>

                                    <button
                                        onClick={() => changeCategories(category.slug)}
                                        className="link">

                                        { category.name }

                                    </button>

                                </li>

                            ))
                            
                        }

                    </ul>

                </div>

            </div>

        </header>

    )

};

export default MenuCategoriesSpecial;