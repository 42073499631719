import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWordpress,
  faFacebookF,
  faYoutube,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons";

import "./style.scss";
import logo from "./../../assets/images/footer-logo.png";

const Footer = () => {
  const scrollTo = (event) => {
    event.preventDefault();

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <Fragment>
      <section className="scrollTop">
        <button className="scrollTop__button" onClick={scrollTo}>
          <FontAwesomeIcon className="" icon={faArrowAltCircleUp} />
        </button>
      </section>

      <address className="copyright">
        <div className="grid">
          <p className="copyright__text">
            <img
              className="copyright__image"
              src={logo}
              alt="Acesso Colaborador - ICL"
            />
            | Todos os direitos reservados.
          </p>
        </div>
      </address>
    </Fragment>
  );
};

export default Footer;
