import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

const Loading = ({ percent }) => {

    return(

        <section className="loading">

            <figure>
                <FontAwesomeIcon className="loading__icon" icon={faCog} size="3x" spin />
                <figcaption className="loading__text">
                    
                    Carregando...
                    
                    {
                        percent && percent > 0 &&
                            <strong>Enviando: { percent }%</strong>
                    }

                </figcaption>

            </figure>

        </section>

    )

};

export default Loading;