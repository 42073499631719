import ModuleProvider from '../../contexts/Module';

import PrivateArea from './../../components/PrivateArea';
import WrapperModule from './../../components/WrapperModule';

const Module = () => {

    return(
        
        <ModuleProvider>

            <PrivateArea>

                <WrapperModule />

            </PrivateArea>

        </ModuleProvider>
        
    )

};

export default Module;