import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import Modal from "react-modal";

import { ModuleContext } from "./../../contexts/Module";

import useLoading from "./../../hooks/useLoading";

import Form from "./../Form";
import { InputDatalist, InputFile } from "./../Fields";
import { requestApiFile } from "./../../services/Request";

import { getToken } from "./../../services/Auth";
import { showDialogError, showDialogToast } from "../../helpers/Utils";

Modal.setAppElement("#root");

const EditFileModal = ({ isOpen, onRequestClose }) => {
  const moduleContext = useContext(ModuleContext);

  const token = getToken();

  const { category } = useParams();
  const history = useHistory();

  const { register, handleSubmit, errors, reset } = useForm();
  const form = useRef();

  const [changeFile, setChangeFile] = useState(false);
  const [fileCurrent, setFileCurrent] = useState({});

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [loader, showLoader, hideLoader] = useLoading(uploadPercentage);

  useEffect(() => {
    if (moduleContext.isEditFilesModalOpen) {
      setFileCurrent(moduleContext.fileEditCurrent);
      setChangeFile(false);

      reset({
        category: moduleContext.fileEditCurrent.category.name,
      });
    }
  }, [moduleContext.isEditFilesModalOpen]);

  const submitForm = (data) => {
    showLoader(true);

    const formData = new FormData(form.current);
    formData.append("module", moduleContext.module.slug);
    formData.append("_method", "PUT");

    requestApiFile(
      "/api/files",
      "POST",
      token,
      formData,
      moduleContext.fileEditCurrent.id,
      setUploadPercentage
    )
      .then((response) => {
        const responseSlugCategory = response.content.category.slug;

        if (category && category !== responseSlugCategory) {
          // setTimeout(() => {

          history.push(
            `/modulo/${moduleContext.module.slug}/categoria/${responseSlugCategory}`
          );

          // }, 2000);
        } else {
          moduleContext.saveUpdateModule();
        }

        showDialogToast("success", response.message);
        onRequestClose(); // Fechar modal
        hideLoader(false); // Fechar loader
      })
      .catch((error) => {
        showDialogError("error", error.message);
        hideLoader(false);
      });
  };

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <button
          type="button"
          onClick={onRequestClose}
          className="react-modal-close"
        >
          [x] Fechar
        </button>

        <Form
          onSubmit={handleSubmit(submitForm)}
          foRef={form}
          encType="multipart/form-data"
        >
          {changeFile ? (
            <InputFile
              label="Arquivo"
              name="file"
              inputRef={register({ required: true })}
              error={errors.file}
            />
          ) : (
            <figure className="file-default">
              <span className="file-default__name">
                Nome do arquivo:
                <b>{fileCurrent?.name}</b>
              </span>
              <button
                className="file-default__button"
                onClick={() => setChangeFile(true)}
              >
                [Alterar arquivo]
              </button>
            </figure>
          )}

          <InputDatalist
            label="Categoria"
            list="categories"
            name="category"
            inputRef={register({ required: true })}
            options={moduleContext.module.categories}
            error={errors.category}
            filled="true"
          />

          <button className="form__button" type="submit">
            Enviar
          </button>
        </Form>
      </Modal>

      {loader}
    </Fragment>
  );
};

export default EditFileModal;
