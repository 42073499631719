import { useContext, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { ModuleContext } from '../../../contexts/Module';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faDownload } from '@fortawesome/free-solid-svg-icons';

import './styles.scss'

const Card = ({ id, file, index, moveCard }) => {

    const moduleContext = useContext(ModuleContext);
    const { module, categories } = moduleContext;
    const category = categories[0];

    const baseUrlResource = `${process.env.REACT_APP_DOMAIN}/storage`;

    const ref = useRef(null);

    const [{ handlerId }, drop] = useDrop({
        accept: 'card',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'card',
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    return(
        
        <div
            className="card"
            ref={ ref }
            style={{ opacity }}
            data-handler-id={ handlerId }

        >

            <span>{ index+1 }</span>

            <div className="card__content card__content--normal">

                <figure>

                    <img 
                        className="card__image"
                        src={ `${baseUrlResource}/${module.style.files.normal}` } 
                        alt={ file.name }/>

                    <figcaption className="card__title">{ file.name }</figcaption>

                    {/* <FontAwesomeIcon className="card__icon" icon={ faDownload } /> */}

                </figure>

                <p className="card__details">

                    <span className="card__text card__text--module">{ module.short_title }</span>
                    <span className="card__text card__text--category">{ category.name }</span>

                </p>

            </div>

        </div>

    )

};

export default Card;