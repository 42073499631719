import { useState } from "react";
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';

export const InputText = (props) => {

    const { name, type, inputRef, label, error, value, filled, autoComplete } = props;
    const [classHtml, setClassHtml] = useState(filled === 'true' ? 'form__field form__field--text hasValue': 'form__field form__field--text');
    const [autoCompleteHtml, setAutoCompleteHtml] = useState(autoComplete !== undefined ? 'new-password' : 'off');

    const changeValuesInput = event => {
        event.target.value !== '' ? 
            setClassHtml('form__field form__field--text hasValue') : 
            setClassHtml('form__field form__field--text')
    };

    return(

        <div className="form__containerGroup">

            <div className="form__group">

                <input
                    className={ classHtml }
                    type={ type }
                    name={ name }
                    ref={ inputRef }
                    defaultValue={ value }
                    onBlur={ event => changeValuesInput(event) }
                    autoComplete={ autoCompleteHtml }                    
                    />

                <label className="form__label" htmlFor="">{ label }</label>

            </div>

            <span className="form__message">{error?.type === 'required' && `Campo ${label} é obrigatório!`}</span>       
            <span className="form__message">{error?.type === 'validate' && `${error?.message}`}</span>       

        </div>

    )
};

export const InputTextMask = (props) => {

    const { label, name, control, inputRef, error, mask, defaultValue, filled } = props;
    const [classHtml, setClassHtml] = useState(filled === 'true' ? 'form__field form__field--text hasValue': 'form__field form__field--text');

    const changeValuesInput = event => {
        event.target.value !== '' ? 
            setClassHtml('form__field form__field--text hasValue') : 
            setClassHtml('form__field form__field--text')
    };

    return(

       <div className="form_containerGroup">
           <div className="form__group">

                <Controller
                    name= { name }
                    rules={ inputRef }
                    control={ control }
                    defaultValue={ defaultValue }
                    render={ props => (
                        <InputMask
                            name={ props.name }
                            value={ props.value }
                            mask={ mask }                        
                            className={ classHtml } 
                            autoComplete="off"
                            onBlur={ event => changeValuesInput(event) }
                            onChange={ props.onChange }
                            />
                        )}
                    />

                <label className="form__label" htmlFor="">{ label }</label>

           </div>
           <span className="form__message">{error && `Campo ${label} é obrigatório!`}</span>
       </div>
        
    )

}

export const InputDatalist = props => {

    const { label, name, inputRef, list, options, error, filled, defaultValue } = props;

    const [classHtml, setClassHtml] = useState(filled === 'true' ? 'form__field form__field--text hasValue': 'form__field form__field--text');

    const changeValuesInput = event => {
        event.target.value !== '' ? 
            setClassHtml('form__field form__field--text hasValue') : 
            setClassHtml('form__field form__field--text')
    };

    return (

        <div className="form__containerGroup">

            <div className="form__group">

                <input 
                    className={ classHtml }
                    name={ name } 
                    list={ list }
                    ref={ inputRef }
                    onBlur={ event => changeValuesInput(event) }
                    defaultValue={ defaultValue }
                    autoComplete="off"
                    />
                    
                <label className="form__label" htmlFor="">{ label }</label>

                <datalist id={ list }>
                    {
                        options && options.map(option => (

                            <option value={ option.name } key={ option.slug } />

                        ))
                    }
                </datalist>


            </div>
            <span className="form__message">{error && `Campo ${label} é obrigatório!`}</span>

        </div>

    )

}

export const InputFileImage = (props) => {

    const { name, inputRef } = props;
    const [file, setFile] = useState();
    const [errors, setErrors] = useState([]);

    const handlerInputFile = event => {

        const file = event.target.files[0];
        let invalid = [];     
        
        if(file) {

            if(file.size > 1000000) invalid.push('Tamanho de imagem máximo ultrapassado: 1MB');
            if(file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/png') invalid.push('Tipo de arquivo invalido. Tente arquivos: jpg/jpeg/png');
    
            setErrors(invalid);

            if(invalid.length === 0) {

                const image = URL.createObjectURL(file);
                const name = file.name;
                setFile({ image, name });

            }


        }

    };

    return(

        <div className="form__containerGroup">

            <div className="form__group">

                <label className="form__labelImagem" htmlFor={ name }>

                    <input
                        className="form__field form__field--fileImage"
                        id={ name }
                        type="file"
                        name={ name }
                        ref={ inputRef }
                        onChange={(event) => handlerInputFile(event)}
                        accept="image/x-png,image/gif,image/jpeg"
                        />

                    {
                        file ?

                            <figure className="form__containerImage">
                                <img className="form__image" src={ file.image } alt={ file.name } />
                                <figcaption className="form__textImage">{ file.name }</figcaption>
                            </figure>
                        :

                            <figure className="form__containerImage">
                                <i className="form__image form__image--default"></i>
                                <figcaption className="form__textImage">Acesso ao colaborador</figcaption>
                            </figure>
                    }

                </label>            

            </div>
            
            {
                errors && errors.map((error, index) => (
                    <span className="form__message form__message--center" key={ index }>{ error }</span>
                ))
            }

        </div>

    )

    
}

export const InputFile = props => {

    const { label, name, inputRef, error, multiple } = props;
    const [file, setFile] = useState(false);

    const handlerInputFile = event => {

        const files = event.target.files.length;

        if(files > 0) {

            const file = event.target.files[0];

            if (files === 1) {

                setFile({ name: file.name });

            } else {

                setFile({ name: `${file.name} e outros...` });

            }

        }

    }


    return (

        <div className="form__containerGroup">

            <div className="form__group">

                <label className="form__labelFile" htmlFor={ name }>

                    {
                        multiple ? (
                            <input 
                                className="form__field form__field--file"
                                id={ name }
                                type="file"
                                name={ name }
                                ref={ inputRef }
                                onChange={ event => handlerInputFile(event) }
                                multiple
                                />
                        ) : (

                        <input 
                            className="form__field form__field--file"
                            id={ name }
                            type="file"
                            name={ name }
                            ref={ inputRef }
                            onChange={ event => handlerInputFile(event) }
                            />
                        )

                    }   

                    {
                        file ? 
                            <figure className="form__containerFile">
                                <figcaption>{ file.name }</figcaption>
                                <FontAwesomeIcon icon={ faFileUpload } size="2x" />
                            </figure> 
                        :
                            <figure className="form__containerFile">
                                <figcaption>Escolha seu arquivo: </figcaption>
                                <FontAwesomeIcon icon={ faFileUpload } size="2x" />
                            </figure>
                        
                    }

                </label>
                

            </div>
            <span className="form__message">{error && `Campo ${label} é obrigatório!`}</span>
        
            
        </div>

    )

}

export const Checkbox = (props) => {

    const { name, type, inputRef, label, onChange } = props

    return(

        <div className="form__group">

            <input
                className="form__field form__field--checkbox"
                type={ type }
                name={ name }
                id={ name }
                ref={ inputRef }
                onChange={ onChange }
            />

            <label htmlFor={ name }>{ label }</label>

        </div>

    )

};


export const Select = (props) => {

    const { label, name, inputRef, error, options, valueDefault, onChange } = props;

    return(

        <div className="form__containerGroup">

            <div className="form__group">

                <select 
                    className="form__field form__field--select"
                    name={ name }
                    ref={ inputRef }
                    onChange={onChange}
                    >
                        <option value="">{ label }</option>
                    {
                        options && options.map(option => (

                            <option
                                key={ option.id }
                                value={option[valueDefault]}
                            >
                                { 
                                
                                    name === 'domain' ? `@${option.name}` : option.name
                                                                        
                                }
                            </option>

                        ))
                    }

                </select>

            </div>

            <span className="form__message">{error && `Campo ${label} é obrigatório!`}</span>

        </div>

    )

}