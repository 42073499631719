import axios from 'axios';

export const requestApi = (url, method = 'GET', type = 'json', token, data = '', params = '', optionsUpload) => {

    let headers = {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
    };
    
    if(type === 'json') {
        headers = { ...headers, "Content-Type": 'application/json' };
        data = JSON.stringify(data);
    }

    if(token !== '') {
        headers = {...headers, "Authorization": `Bearer ${token}`}
    };

    if(params) {
        url = url + params;
    }    

    return axios({

        method: method,
        url: `${process.env.REACT_APP_DOMAIN}${url}`,
        headers: headers,
        data: data
        
    }).then(response => response.data)
    .catch(error => { 

        throw new Error(
            Object.values(error.response.data.errors).length > 0 ? 
            JSON.stringify([error.response.data.errors]) :
            JSON.stringify([{ default: [error.response.data.message] }]) 
        );

    });

};

export const requestApiFile = (url, method = 'POST', token, data, params = '', setState) => {

    url = `${process.env.REACT_APP_DOMAIN}${url}`;
    if(params) url = `${url}/${params}`;


    return axios({
        url: url,
        method: method,
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Authorization": `Bearer ${token}`
        },
        data: data,
        onUploadProgress: progressEvent => {

            const { loaded, total } = progressEvent;

            let percent = Math.floor( (loaded * 100) / total);

            if(percent < 100) {
                setState(percent);
            }

        },

    }).then(response => response.data)
    .catch(error => { 

        throw new Error(
            Object.values(error.response.data.errors).length > 0 ? 
            JSON.stringify([error.response.data.errors]) :
            JSON.stringify([{ default: [error.response.data.message] }]) 
        );

    });

}