import { Fragment, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { requestApi } from "./../../services/Request";
import { getToken, removeToken } from "../../services/Auth";
import { showDialogError } from "../../helpers/Utils";

import EditProfile from "../EditProfile";

import "./style.scss";
import logo from "./../../assets/images/header-logo.png";

const Header = () => {
  const history = useHistory();
  const classMenu = history.location.pathname;
  const [editProfile, setEditProfile] = useState(false);
  const [menu, setMenu] = useState(false);
  const token = getToken();

  const getOut = (event) => {
    requestApi("/api/auth/logout", "POST", "json", token, "", "")
      .then((response) => {
        removeToken();
        history.push("/login");
      })
      .catch((error) => showDialogError("error", error.message));
  };

  return (
    <Fragment>
      <header
        className={classMenu !== "/" ? "header-main fixed" : "header-main"}
      >
        <div className="grid">
          <figure className="logo">
            <Link to="/" title="Acesso Colaborador - ICL">
              <img
                className="logo__image"
                src={logo}
                alt="Acesso Colaborador - ICL"
              />
            </Link>
          </figure>

          <nav className={!menu ? "menu" : "menu open"}>
            <button className="menu__button" onClick={() => setMenu(!menu)}>
              <FontAwesomeIcon
                className="menu__icon menu__icon--user"
                icon={faUserCircle}
              />
              Acesso ao colaborador
              <FontAwesomeIcon
                className="menu__icon menu__icon--arrow"
                icon={faChevronRight}
              />
            </button>

            <ul className="menu__list">
              <li className="menu__item">
                <button
                  className="menu__link menu__link--edit"
                  onClick={() => setEditProfile(!editProfile)}
                >
                  Editar dados
                </button>
              </li>

              <li className="menu__item">
                <button
                  className="menu__link menu__link"
                  onClick={(event) => getOut(event)}
                >
                  Sair
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <EditProfile state={editProfile} setState={setEditProfile} />
    </Fragment>
  );
};

export default Header;
