import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import { showDialogToast, showDialogError } from './../../helpers/Utils';
import { requestApi } from './../../services/Request';
import { getToken } from './../../services/Auth';

import { ModuleContext } from './../../contexts/Module';

import useLoading from './../../hooks/useLoading';

import Card from './Card';

import './styles.scss';

Modal.setAppElement('#root');

const EditSortableFiles = () => {

    const moduleContext = useContext(ModuleContext);
    const category = moduleContext?.categories[0];

    const [files, setFiles] = useState([]);

    const token = getToken();

    const [loader, showLoader, hideLoader] = useLoading();

    useEffect(() => {

        setFiles(category?.files);

    }, [moduleContext.categories])

    const moveCard = useCallback((dragIndex, hoverIndex) => {

        const dragCard = files[dragIndex];

        setFiles(update(files, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard],
            ],
        }));

    }, [files]);

    const submitForm = () => {

        showLoader(true);
        
        const idFiles = files.map(file => (file.id));
        const data = { files: idFiles };
    
        requestApi('/api/files/order', 'POST', 'json', token, data, '', '')
            .then(response => {

                moduleContext.handleToogleEditSortable(false);
                showDialogToast('success', response.message);
                moduleContext.saveUpdateModule();

            })
            .catch(error => {

                moduleContext.handleToogleEditSortable(false);
                showDialogError('error', error.message);
                hideLoader(true);

            });
        

    };

    return(

        <Fragment>
        
            <section className="sortable">

            <div className="grid">

                <div className="title">

                    <h2 className="title__text">{ category.name }</h2>

                    <div className="title__actions">

                        <button 
                            className="title__button"
                            onClick={ () => moduleContext.handleToogleEditSortable(false) }>                                                
                                [Descartar alteração]
                        </button>


                    </div>

                </div>

                <DndProvider backend={ HTML5Backend }>

                    <div className="list">

                        {

                            files &&
                            files.map((file, index) => (

                                <Card 
                                    key={ file.id }
                                    index={ index}
                                    id={ file.id }
                                    file={ file }
                                    moveCard={ moveCard }
                                />

                            ))

                        }

                        
                    </div>

                    <button
                        className="sortable__button"
                        type="button"
                        onClick={ () => submitForm() }>
                        Salvar Ordem
                    </button>

                </DndProvider>

            </div>

        </section>
            
            { loader }

        </Fragment>

    )

};

export default EditSortableFiles;
