import { Fragment, useContext } from 'react';
import { useHistory } from 'react-router';

import { ModuleContext } from './../../contexts/Module';

import useLoading from './../../hooks/useLoading';

import File from './../File';

import { getToken } from '../../services/Auth';
import { requestApi } from '../../services/Request';
import { showDialogError, showDialogToast } from '../../helpers/Utils';

const ListCategory = () => {

    const token = getToken();

    const history = useHistory();

    const moduleContext = useContext(ModuleContext);
    const { module } = moduleContext;
    const category = moduleContext.categories[0];

    const [loader, showLoader, hideLoader] = useLoading();

    const handleEditCategory = (category) => {

        moduleContext.saveCategoryEditCurrent(category);
        moduleContext.handleToogleEditCategoryModal(true); 

    };

    const handleDeleteCategory = (category) => {

        const confirm = window.confirm(`Você realmente deseja apagar a categoria: ${category.name}? Essa ação irá apagar todos os arquivos vinculados a ela!`);

        if(confirm) {

            showLoader(true);

            const data = {
                '_method': 'DELETE',
                'module': module.slug 
            };

            requestApi('/api/categories', 'POST', 'json', token, data, `/${category.slug}`)
                .then(response => {

                    history.push(`/modulo/${module.slug}`);
                    showDialogToast('success', response.message);
                    hideLoader(false); // Fechar loader

                }).catch(error => {

                    showDialogError('error', error.message);
                    hideLoader(false); // Fechar loader

                });

        }
        
    };

    const handleEditSortableFiles = () => {

        moduleContext.handleToogleEditSortable(true);

    };

    return(
        
        <Fragment>

            {
                category &&

                    <section className="categories" key={ category.id }>

                        <div className="grid">

                            <div className="title">

                                <h2 className="title__text">{ category.name }</h2>

                                {
                                    module.editable && (

                                        <div className="title__actions">

                                            <button 
                                                className="title__button"
                                                onClick={ () => handleEditSortableFiles() }>                                                
                                                    Ordernar Arquivos
                                            </button>

                                            |

                                            <button 
                                                className="title__button"
                                                onClick={ () => handleEditCategory(category) }>                                                
                                                    Editar
                                            </button>

                                            |

                                            <button 
                                                className="title__button"
                                                onClick={ () => handleDeleteCategory(category) }>
                                                    Excluir
                                            </button>


                                        </div>

                                    )
                                }

                            </div>

                            <ul className="list">

                                {

                                    category.files && 
                                    category.files.map(file => (

                                        <li className="item" key={ file.id }>

                                            <File
                                                module={ module }
                                                category={ category }
                                                file={ file } />

                                        </li>

                                    ))
                                    
                                }

                            </ul>

                        </div>

                    </section>

            }

            { loader }

        </Fragment>

    )

};

export default ListCategory;