import { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBars, faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';

import { useCompany } from '../../contexts/Company';

import ActiveFilter from './ActiveFilters';
import Cultures from './Cultures';
import Categories from './Categories';
import Products from './Products';

import './styles.scss'

const AsideFilter = () => {

    const { activeCultures, activeProducts, activeCategory } = useCompany();

    const [isWideVersion, setIsWideVersion] = useState(false);
    const [isActiveMenuMobile, setIsActiveMenuMobile] = useState(false);
    const [isActiveFilterMobile, setIsActiveFilterMobile] = useState(false);

    useEffect(() => {
        
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        setIsActiveMenuMobile(false);
        setIsActiveFilterMobile(false);

    }, [activeCultures, activeProducts, activeCategory]);

    useEffect(() => {

        if(window.innerWidth >= 999) {
            setIsWideVersion(true);
        } else {
            setIsWideVersion(false);
        }

    }, []);

    return(

        <Fragment>

            {

                isWideVersion ? (

                    <aside className="aside-filter">

                        <ActiveFilter />

                        <Cultures />

                        <Products />

                        <Categories />

                    </aside>

                ) : (

                    <aside className="aside-filter">

                        <section className="menu">

                            <button
                                onClick={() => setIsActiveMenuMobile(!isActiveMenuMobile)}
                            >
                                {
                                    !isActiveMenuMobile ? (
                                        <FontAwesomeIcon icon={faBars}/>
                                        ): (
                                        <FontAwesomeIcon icon={faTimes}/>
                                    )
                                }

                            </button>

                            <button
                                disabled={ activeCultures.length + activeProducts.length <= 0 ? true : false}
                                onClick={() => setIsActiveFilterMobile(!isActiveFilterMobile)}
                            >
                                <FontAwesomeIcon icon={faFilter} />
                                Filtros
                            </button>                            
                        
                        </section>

                        {
                            isActiveMenuMobile && (

                                <div className="drawer filters">

                                    <div className="grid">

                                        <Cultures />

                                        <Products />

                                        <Categories />

                                    </div>

                                </div>

                            )
                        }

                        {

                            isActiveFilterMobile && (

                                <div className="drawer filtersActive">

                                    <div className="grid">

                                        <div className="drawer__header">

                                            <button
                                                onClick={() => setIsActiveFilterMobile(false)}
                                            >
                                                <FontAwesomeIcon icon={faArrowLeft}/>
                                            </button>

                                            Filtros

                                        </div>

                                        <ActiveFilter />

                                    </div>

                                </div>

                            )

                        }   

                    </aside>

                )

            }

        </Fragment>


    )

};

export default AsideFilter;