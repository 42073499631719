import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useCompany } from '../../contexts/Company';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

import { showDialogError } from '../../helpers/Utils';
import { getToken } from '../../services/Auth';
import { requestApi } from '../../services/Request';

export const Cultures = () => {

    const { module, company } = useParams();

    const { activeCultures, saveActiveCultures} = useCompany();

    const token = getToken();

    const [cultures, setCultures] = useState([]);
    const [isActiveListCulture, setIsActiveListCulture] = useState(false);

    useEffect(() => {
        requestApi('/api/cultures', 'GET', 'json', token, '', `?module=${module}&company=${company}&has-files=true`)
            .then(response => setCultures(response.content.data))
            .catch(error => showDialogError('error', error.message))

    }, [module]);

    const changeCultures = event => {

        if (event.target.checked) {

            saveActiveCultures([...activeCultures, {
                slug: event.target.name,
                name: event.target.value
            }]);

        } else {

            const newCultures = activeCultures.filter(culture => culture.slug !== event.target.name);
            saveActiveCultures([...newCultures]);

        }

    };

    if(cultures.length === 0) {
        return null;
    }

    return(

        <section className={ isActiveListCulture ? 'cultures active': 'cultures' }>

            <button onClick={ () => setIsActiveListCulture(!isActiveListCulture) }>
                
                Culturas

                <FontAwesomeIcon icon={ !isActiveListCulture ? faPlus : faMinus } />

            </button>

            <ul>

                { 
                
                    cultures && 
                    cultures.map(culture => (

                        <li key={ culture.slug }>

                            <label htmlFor={ culture.slug }>
                                <input 
                                    type="checkbox" 
                                    name={ culture.slug } 
                                    id={ culture.slug }
                                    value={ culture.name }
                                    onChange={ changeCultures } 
                                    // checked={activeCultures.includes(culture.slug)}
                                    checked={ activeCultures.findIndex(element => element.slug === culture.slug) >= 0 ? true : false }
                                    />

                                { culture.name }

                            </label>

                        </li>

                    ))    

                }

            </ul>

        </section>


    )

};

export default Cultures;