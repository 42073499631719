import './style.scss';
import imageValues from './../../assets/images/empresa-valores-icones.png';
import imageMission from './../../assets/images/empresa-missao-icones.png';
import imageVision from './../../assets/images/empresa-visao-icones.png';

const Company = () => {

    return(
        
        <section className="company">

            <div className="grid">

                <h2 className="company__title">O melhor para o <span>futuro</span></h2>
                <h3 className="company__subtitle">Inovação e qualidade nas soluções</h3>

                <ul className="company__list">

                    <li className="company__item">

                        <figure className="card">

                            <span className="card__icon">
                                <img src={ imageVision } alt="Visão"/>
                            </span>

                            <figcaption className="card__text">
                                <strong className="card__title">Visão</strong>
                                Ser uma empresa de pessoas
                                inspiradoras que juntas geram valores
                                diferenciados ao Agronegócio.
                            </figcaption>

                        </figure>
                    </li>

                    <li className="company__item">

                        <figure className="card">

                            <span className="card__icon">
                                <img src={ imageValues } alt="Valores"/>
                            </span>

                            <figcaption className="card__text">
                                <strong className="card__title">Valores</strong>
                                Pessoas alinhadas com objetivos
                                e sonhos em comum se tornam um maior
                                diferencial de uma organização.
                                Foco no que é relevante para o resultado.
                                Criação de valor constante ao Cliente.
                            </figcaption>

                        </figure>
                    
                    </li>

                    <li className="company__item">

                        <figure className="card">

                            <span className="card__icon">
                                <img src={ imageMission } alt="Missão"/>
                            </span>

                            <figcaption className="card__text">
                                <strong className="card__title">Missão</strong>
                                Pesquisar, desenvolver, produzir, comprovar,
                                divulgar e comercializar as melhores
                                soluções para nutrição de plantas,
                                suplementações de sementes e revitalização
                                de solo para nossos clientes no mercado local
                                e internacional da agricultura.
                            </figcaption>

                        </figure>

                    </li>

                </ul>

            </div>

        </section>

    );

};

export default Company;