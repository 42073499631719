import { Fragment, useContext } from 'react';

import { ModuleContext } from './../../contexts/Module';

import useLoading from '../../hooks/useLoading';

import { getToken } from './../../services/Auth';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import { requestApi } from './../../services/Request';
import { showDialogError, showDialogToast } from './../../helpers/Utils';

import './style.scss';

const File = ({ module, category, file }) => {

    const moduleContext = useContext(ModuleContext);

    const token = getToken();

    const [loader, showLoader, hideLoader] = useLoading();

    const baseUrlResource = `${process.env.REACT_APP_DOMAIN}/storage`;
    const linkFileDownload = `${baseUrlResource}/${file.uri}?token=${token}`;

    const handleEditFile = (file) => {

        moduleContext.saveFileEditCurrent(file);
        moduleContext.handleToogleEditFilesModal(true); 

    };

    const handlerDeleteFile = (file) => {

        const confirm = window.confirm(`Você realmente deseja apagar o arquivo: ${file.name}`);

        if(confirm) {

            showLoader(true);

            const data = {'_method': 'DELETE'};

            requestApi('/api/files', 'POST', 'json', token, data, `/${file.id}`)
                .then(response => {

                    showDialogToast('success', response.message);
                    moduleContext.saveUpdateModule();
                    hideLoader(false);

                })
                .catch(error => {

                    showDialogError(error => error.message);
                    hideLoader(false);

                })

        }

    };

    return(

        <Fragment>

            <a 
                className="card"
                href={ linkFileDownload }
                download={ file.name } 
                target="_blank" 
                rel="noreferrer"
            >

                <div className="card__content card__content--normal">

                    <figure>

                        <img 
                            className="card__image"
                            src={ `${baseUrlResource}/${module.style.files.normal}` } 
                            alt={ file.name }/>

                        <figcaption className="card__title">{ file.name }</figcaption>

                        <FontAwesomeIcon className="card__icon" icon={ faDownload } />

                    </figure>

                    <p className="card__details">

                        <span className="card__text card__text--module">{ module.short_title }</span>
                        <span className="card__text card__text--category">{ category.name }</span>

                    </p>

                </div>

                <div className="card__content card__content--hover" style={{ color: module.style.color }}>

                    <figure>

                        <img 
                            className="card__image"
                            src={ `${baseUrlResource}/${module.style.files.hover}` } 
                            alt={ file.name }/>

                        <figcaption className="card__title">{ file.name }</figcaption>

                        <FontAwesomeIcon className="card__icon" icon={ faDownload } />

                    </figure>

                    <p className="card__details">

                        <span className="card__text card__text--module">{ module.short_title }</span>
                        <span className="card__text card__text--category">{ category.name }</span>

                    </p>

                </div>

            </a>

            {

                module.editable && (

                    <footer className="actions">

                        <button
                            className="actions__button"
                            onClick={ () => handleEditFile(file) }>
                                Editar
                        </button>

                        |

                        <button
                            className="actions__button"
                            onClick={ () => handlerDeleteFile(file) }>                            
                                Excluir
                        </button>

                    </footer>

                )

            }

            { loader }

            </Fragment>

    )

};

export default File;