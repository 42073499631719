import { Fragment, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ModuleContext } from './../../contexts/Module';

import ContentModule from './../ContentModule';
import ContentModuleSpecial from './../ContentModuleSpecial';

import { getToken } from './../../services/Auth';
import { requestApi } from './../../services/Request';
import { showDialogError } from './../../helpers/Utils';

const WrapperModule = () => {

    const { module } = useParams();
    const token = getToken(); 

    const moduleContext = useContext(ModuleContext);
    

    useEffect(() => {
        
        moduleContext.saveCategories([]); // Reset Categories
        
        requestApi('/api/modules', 'GET', 'json', token, '', `/${module}` )
            .then(response => moduleContext.saveModule(response.content))
            .catch(error => showDialogError('error', error.message));
        
        
    }, [moduleContext.updateModule, module]);

    return(

        <Fragment>

            {
                !moduleContext.module.special ? (
                    
                    <ContentModule/>

                ): (

                    <ContentModuleSpecial />

                )
            }

        </Fragment>
        
    )

};

export default WrapperModule;