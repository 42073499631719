import { Fragment } from "react";
import { Link } from 'react-router-dom';

import logo from './../../assets/images/login-logo.png';
import './style.scss';

const PublicArea = (props) => {

    const { children, className, removeLogo } = props; 
    
    return(
        <Fragment>
            
            <main className={className}>

                <div className="content">

                    {
                        !removeLogo &&

                            <header className="header">

                                <figure className="logo">
                                    <Link className="logo__link" to="/login">
                                        <img className="logo__image" src={logo} alt="Acesso ao Colaborador" />
                                    </Link>

                                    <figcaption className="logo__text">
                                        Acesso ao Colaborador
                                    </figcaption>

                                </figure>

                            </header>

                    }

                    { children }

                </div>

            </main>

        </Fragment>
    );

};

export default PublicArea;