import { useRef, useEffect, useState, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import useLoading from './../../hooks/useLoading';

import Form from './../../components/Form';
import PublicArea from "./../../components/PublicArea";

import { requestApi } from './../../services/Request/index';
import { showDialogToast, showDialogError, prepareFormData } from './../../helpers/Utils';
import { InputText, InputFileImage, InputTextMask, Select } from './../../components/Fields';


const Register = () => {

    const [domains, setDomains] = useState([]);
    const [managers, setManagers] = useState([]);
    const [banks, setBanks] = useState([]);
    const [managerCurrent, setManagerCurrent] = useState(false);

    const { register, control, watch, handleSubmit, errors } = useForm();
    const form = useRef();
    const history = useHistory();
    const [loader, showLoader, hideLoader] = useLoading();

    const submitForm = data => {

        showLoader(true);

        const formData = new FormData(form.current);
        let addsFormData = [{ name: 'email', value: `${data.email}@${data.domain}`}];

        if(!managerCurrent) {

            const newsAddsFormData = [
                { name: 'bank_account[agency]', value: ''},
                { name: 'bank_account[bank_id]', value: ''},
                { name: 'bank_account[account]', value: ''},
                { name: 'bank_account[document]', value: ''},
            ];

            addsFormData = [...addsFormData, ...newsAddsFormData];
        }

        const newFormData = prepareFormData(formData, addsFormData, ['email', 'domain']);

        requestApi('/api/users', 'POST', 'formData', '', newFormData)
            .then(response => {

                hideLoader(false);
                showDialogToast('success', response.message);
                setTimeout(() => { history.push('/login') }, 3500);

            })
            .catch(error => {

                hideLoader(false);
                showDialogError('error', error.message);

            });

    }

    useEffect(() => {
        
        // domains
        requestApi('/api/domains', 'GET', 'json', '', '')
            .then(response => setDomains(response.content.data))
            .catch(error => showDialogError('error', error.message));

        // managers
        requestApi('/api/managers', 'GET', 'json', '', '')
            .then(response => setManagers(response.content.data))
            .catch(error => showDialogError('error', error.message));

        // banks
        requestApi('/api/banks', 'GET', 'json', '', '')
            .then(response => setBanks(response.content.data))
            .catch(error => showDialogError('error', error.message));

    }, []);

    const changeManagers = event => {
        const managerFound = managers.find(element => element.id === parseInt(event.target.value));
        managerFound && managerFound.work_on_field ? setManagerCurrent(true) : setManagerCurrent(false);    
    };

    return(
        
        <PublicArea className="publicArea register" removeLogo="true">

            <Form onSubmit={ handleSubmit(submitForm) } foRef={ form } encType="multipart/form-data">


                <InputFileImage
                    label=""
                    name="avatar"
                    inputRef={ register() }
                    />

                <h1 className="form__title">Cadastro</h1>

                <InputText
                    label="Nome completo"
                    type="text"
                    name="name"
                    inputRef={ register({ required: true }) }
                    error={errors.name}
                    />

                <div className="form__row form__row--two">

                    <InputText
                        label="E-mail"
                        type="text"
                        name="email"
                        inputRef={ register({ required: true })}
                        error={errors.email}
                        />

                    <Select
                        label="Domínio"
                        name="domain"
                        inputRef={ register({ required: true }) }
                        error={ errors.domain }
                        options={ domains }
                        valueDefault="name"
                    />

                </div>

                <Select
                    label="Escolha gerente"
                    name="manager_id"
                    inputRef={ register({ required: true}) }
                    error={ errors.manager_id }
                    options={ managers }
                    valueDefault="id"
                    onChange={ event => changeManagers(event)}
                    />

                {
                    managerCurrent &&
               
                    <Fragment>
                        <div className="form__row form__row--three">
                            
                            <Select 
                                label="Escolha o banco"
                                name="bank_account[bank_id]"
                                inputRef={ register( { required: true}) }
                                error={ errors?.bank_account?.bank_id }
                                options={ banks }
                                valueDefault="id"
                                />

                            <InputText
                                label="Agência"
                                type="text"
                                name="bank_account[agency]"
                                inputRef={ register({ required: true })}
                                error={ errors?.bank_account?.agency }
                                />

                            <InputText
                                label="Nº da conta"
                                type="text"
                                name="bank_account[account]"
                                inputRef={ register({ required: true })}
                                error={ errors?.bank_account?.account }
                                />

                        </div>

                        <InputTextMask
                            label="Documento CPF"
                            name="bank_account[document]"
                            inputRef={{ required: true }}
                            error={ errors?.bank_account?.document }
                            control={ control }
                            mask="999.999.999-99"
                            defaultValue=""
                            />
                    </Fragment>
                    
                }

                <InputTextMask
                    label="Celular corporativo"
                    name="phone"
                    inputRef={{ required: true }}
                    error={ errors.phone }
                    control={ control }
                    mask="(99) 99999-9999"
                    defaultValue=""
                    />

                <InputText
                    label="Nova senha"
                    type="password"
                    name="password"
                    inputRef={ register({ required: true })}
                    error={ errors.password }
                    autoComplete="autocomplete"
                    />

                <InputText
                    label="Confirme a nova senha"
                    type="password"
                    name="password_confirmation"
                    inputRef={ register({ required: true, validate: value => value === watch('password') || "Senhas divergentes" })}
                    error={ errors.password_confirmation }
                    />

                <button className="form__button" type="submit">Enviar</button>                

            </Form>

            { loader }
        
        </PublicArea>
    );

};

export default Register;