import { Fragment, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';

import { ModuleContext } from './../../contexts/Module';

import useLoading from './../../hooks/useLoading';

import Form from './../Form';
import { InputText } from './../Fields';

import { requestApi } from '../../services/Request';
import { getToken } from '../../services/Auth';
import { showDialogError, showDialogToast } from '../../helpers/Utils';

const EditCategoryModal = ({ isOpen, onRequestClose }) => {
    
    const token = getToken();

    const history = useHistory();

    const moduleContext = useContext(ModuleContext);

    const { register, handleSubmit, errors, reset} = useForm();

    const [loader, showLoader, hideLoader] = useLoading();

    useEffect(() => {

        if(moduleContext.isEditCategoryModalOpen) {

            reset({
                category: moduleContext.categoryEditCurrent.name
            })

        }

    }, [moduleContext.isEditCategoryModalOpen]);

    const submitForm = data => {

        showLoader(true);

        const newData = {
            ...data,
            'module': moduleContext.module.slug,
            '_method': 'PUT'
        };

        requestApi('/api/categories','POST', 'json', token, newData, `/${moduleContext.categoryEditCurrent.slug}`)
            .then(response => {

                history.push(`/modulo/${moduleContext.module.slug}/categoria/${response.content.slug}`);

                showDialogToast('success', response.message);
                onRequestClose(); // Fechar modal
                hideLoader(false); // Fechar loader

            })
            .catch(error => {

                showDialogError('error', error.message);
                hideLoader(false);

            });
    };

    return(

        <Fragment>

            <Modal
                isOpen={ isOpen }
                onRequestClose={ onRequestClose }
                overlayClassName="react-modal-overlay"
                className="react-modal-content">

                <button 
                    type="button" 
                    onClick={ onRequestClose }
                    className="react-modal-close">
                    [x] Fechar
                </button>

                <Form onSubmit={ handleSubmit(submitForm) }>

                    <InputText
                        label="Categoria"
                        type="text"
                        name="category"
                        inputRef={ register({ required: true }) }
                        error={errors.category}
                        filled="true"
                    />

                    <button 
                        className="form__button" 
                        type="submit">
                            Enviar
                    </button>

                </Form>


            </Modal>

            { loader }

        </Fragment>

    )

};

export default EditCategoryModal;