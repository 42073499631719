import "./style.scss";
import blogImage from "./../../assets/images/blog-imagem.png";

const Blog = () => {
  return (
    <section className="blog">
      <div className="grid">
        <figure className="figure">
          <img
            className="figure__image"
            src={blogImage}
            alt="Falando em agro"
          />
        </figure>

        <div className="information">
          <h4 className="information__title">
            <span>Blog Falando em agro</span>
          </h4>

          <p className="information__text">
            Muita informação,
            <br />
            conhecimento com lives e<br />
            podcast ao seu alcance.
          </p>

          <a
            className="information__link"
            href="https://falandoemagro.com"
            title="Acessar Agora"
            target="_blank"
            rel="noreferrer"
          >
            Acessar Agora
          </a>
        </div>
      </div>
    </section>
  );
};

export default Blog;
