import ModuleProvider from '../../contexts/Module';

import PrivateArea from './../../components/PrivateArea';
import WrapperCategory from './../../components/WrapperCategory';

const Category = () => {

    return(
        
        <ModuleProvider>

            <PrivateArea>

                <WrapperCategory />

            </PrivateArea>

        </ModuleProvider>
        
    )

};

export default Category;