import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug } from '@fortawesome/free-solid-svg-icons';

import PublicArea from './../../components/PublicArea';

const NotFound = () => {

    return(

        <PublicArea className="publicArea login" removeLogo="true">

                <p className="form__messageFeedback form__messageFeedback--error">
                    <FontAwesomeIcon className="form__messageFeedbackIcon" icon={ faBug } size="3x" />
                    Página não encontrada
                    <Link className="form__button" to="/" title="Recupera a Senha">Voltar</Link>
                </p>

        </PublicArea>

    );

};

export default NotFound;