import { Fragment, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ModuleContext } from './../../contexts/Module';

import MenuCategories from './../MenuCategories';
import ResumeModule from './../ResumeModule';
import ListCategory from './../ListCategory';
import CreateFilesModal from './../CreateFilesModal';
import EditFileModal from './../EditFileModal';
import EditCategoryModal from './../EditCategoryModal';
import EditSortableFiles from './../EditSortableFiles';

import { getToken } from './../../services/Auth';
import { requestApi } from './../../services/Request';
import { showDialogError, showDialogToast } from './../../helpers/Utils';

const WrapperCategory = () => {

    const { module, category } = useParams();
    const token = getToken();
    const history = useHistory();

    const moduleContext = useContext(ModuleContext);

    useEffect(() => {
        
        requestApi('/api/modules', 'GET', 'json', token, '', `/${module}` )
            .then(response => moduleContext.saveModule(response.content))
            .catch(error => showDialogError('error', error.message));


    }, [moduleContext.updateModule, category]);

    useEffect(() => {

        const moduleSlug = moduleContext.module.slug;
        const categorySlug = category; // useParams();
        const categories = moduleContext.module.categories;

        if(moduleSlug){

            requestApi('/api/files', 'GET', 'json', token, '', `?module=${moduleSlug}&category=${category}&per_page=all`)
                .then(response => {

                    if(response.content.data.length > 0){
                
                        const index = categories.findIndex(category => category.slug === categorySlug);
                        const newCategory = [{...categories[index], 'files': response.content.data, 'pagination': response.content.meta.pagination}];
                        moduleContext.saveCategories([...newCategory]);
                    
                    } else {

                        showDialogToast('error', 'Categoria Vazia');
                        history.push(`/modulo/${moduleSlug}`);

                    }

                })
                .catch(error => showDialogError('error', error.message));
        }

    }, [moduleContext.module]);

    return(

        <main className="category">

            <MenuCategories gobackHome="false"/>

            <ResumeModule />

            {
                !moduleContext.isEditSortable &&
                    <ListCategory />
            }

            {
                moduleContext.module.editable &&
                
                <Fragment>

                    <CreateFilesModal 
                        isOpen={ moduleContext.isCreateFilesModalOpen }
                        onRequestClose={ () => moduleContext.handleToogleCreateFilesModal(false) }
                    />

                    <EditFileModal
                        isOpen={ moduleContext.isEditFilesModalOpen }
                        onRequestClose={ () => moduleContext.handleToogleEditFilesModal(false) }
                    />

                    <EditCategoryModal
                        isOpen={ moduleContext.isEditCategoryModalOpen }
                        onRequestClose={ () => moduleContext.handleToogleEditCategoryModal(false) }
                    />

                    {
                        moduleContext.isEditSortable &&
                        
                            <EditSortableFiles />   
                    }
                        
                            
                </Fragment>
            }

        </main>

    )

}

export default WrapperCategory;