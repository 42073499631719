import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import useLoading from './../../hooks/useLoading';

import Form from './../../components/Form';
import PublicArea from "./../../components/PublicArea";
import { InputText } from './../../components/Fields';
import { requestApi } from '../../services/Request';
import { showDialogToast, showDialogError } from '../../helpers/Utils';

const RecoverPassword = () => {

    const { register, handleSubmit, errors } = useForm();
    const [loader, showLoader, hideLoader] = useLoading();
    const [submittedForm, setSubmittedForm] = useState();

    const submitForm = data => {

        showLoader(true);
        
        requestApi('/api/auth/forgot', 'POST', 'json', '', data)
            .then(response => {

                hideLoader(false);
                setSubmittedForm(true);
                showDialogToast('success', response.message);
            
            })
            .catch(error => {
                
                hideLoader(false);
                showDialogError('error', error.message);
            
            });


    };

    return(
        
        <PublicArea className="publicArea recoverPassword">

            <Form onSubmit={ handleSubmit(submitForm) }>
                
                <h1 className="form__title">Esqueceu a senha!</h1>

                {
                    !submittedForm ?                
                        <Fragment>

                            <InputText
                                label="E-mail"
                                type="text"
                                name="email"
                                inputRef={ register({ required: true }) }
                                error={ errors.email }
                                />

                            <button className="form__button" type="submit">Enviar</button> 
                        
                        </Fragment>

                    :

                    <p className="form__messageFeedback form__messageFeedback--success">
                        <FontAwesomeIcon className="form__messageFeedbackIcon" icon={faCheckCircle} size="2x" />
                        Um link foi enviado para seu e-mail! <br/>
                        Verifique sua caixa de entrada ou caixa de spam
                    </p>
                
                }
                
            </Form>

            { loader }

        </PublicArea>

    );

};

export default RecoverPassword;