import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; 

import { requestApi } from './../../services/Request';
import { getToken } from './../../services/Auth';
import { showDialogError } from './../../helpers/Utils';

import './style.scss';

const ListModules = () => {

    const token = getToken();
    const [modules, setModules] = useState();

    useEffect(() => {

        requestApi('/api/modules', 'GET', 'json', token, '')
            .then(response => setModules(response.content.data))
            .catch(error => showDialogError('error', error.message));

    }, [token]);

    return(
    
        <section className="modules">

            <div className="grid">

                <ul className="modules__list">

                    {

                        modules && modules.map(module => (

                            <li className="modules__item" key={ module.id }>
    
                                <Link className="card" to={`/modulo/${module.slug}`}>
                                
                                    <span className="card__content card__content--normal" style={{ backgroundColor: module.style.color }}>

                                        <img className="card__icon" src={ `${process.env.REACT_APP_DOMAIN}/storage/${module.style.icons.normal}` } alt={ module.title } />
                                        <span className="card__text">{ module.short_title }</span>

                                    </span>

                                    <span className="card__content card__content--hover">

                                        <img className="card__icon" src={ `${process.env.REACT_APP_DOMAIN}/storage/${module.style.icons.hover}` } alt={ module.title } />
                                        <span className="card__text" style={{ color: module.style.color }}>{ module.short_title }</span>
                                        
                                    </span>

                                </Link>
    
                            </li>
                    
                        ))

                    
                    }
                </ul>

            </div>

        </section>

    );

};

export default ListModules;