import { Fragment, useContext, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Modal from "react-modal";

import { ModuleContext } from "./../../contexts/Module";

import useLoading from "../../hooks/useLoading";

import Form from "./../Form";
import { InputDatalist, InputFile } from "./../Fields";

import { requestApiFile } from "../../services/Request";
import { showDialogError, showDialogToast } from "../../helpers/Utils";
import { getToken } from "../../services/Auth";

Modal.setAppElement("#root");

const CreateFilesModal = ({ isOpen, onRequestClose }) => {
  const { category } = useParams();

  const token = getToken();

  const history = useHistory();

  const form = useRef();
  const { register, handleSubmit, errors } = useForm();

  const moduleContext = useContext(ModuleContext);
  const { module } = moduleContext;

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [loader, showLoader, hideLoader] = useLoading(uploadPercentage);

  function submitForm(data) {
    showLoader(true); // Abre loader

    const formData = new FormData(form.current);
    formData.append("module", module.slug);

    requestApiFile(
      "/api/files",
      "POST",
      token,
      formData,
      "",
      setUploadPercentage
    )
      .then((response) => {
        const responseSlugCategory = response.content.data[0].category.slug;

        if (category && category !== responseSlugCategory) {
          // setTimeout(() => {

          history.push(
            `/modulo/${module.slug}/categoria/${responseSlugCategory}`
          );

          // }, 2000);
        } else {
          moduleContext.saveUpdateModule();
        }

        showDialogToast("success", response.message);
        onRequestClose(); // Fechar modal
        hideLoader(false); // Fechar loader
      })
      .catch((error) => {
        showDialogError("error", error.message);
        hideLoader(false);
      });
  }

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <button
          type="button"
          onClick={onRequestClose}
          className="react-modal-close"
        >
          [x] Fechar
        </button>

        <Form
          onSubmit={handleSubmit(submitForm)}
          foRef={form}
          encType="multipart/form-data"
        >
          <InputFile
            label="Arquivo"
            name="files[]"
            inputRef={register({ required: true })}
            error={errors.file}
            multiple="true"
          />

          <InputDatalist
            label="Categoria"
            list="categories"
            name="category"
            inputRef={register({ required: true })}
            options={module.categories}
            error={errors.category}
          />

          <button className="form__button" type="submit">
            Enviar
          </button>
        </Form>
      </Modal>

      {loader}
    </Fragment>
  );
};

export default CreateFilesModal;
