import { Fragment, useContext, useEffect, useRef } from 'react';

import MenuCategories from './../MenuCategories';
import ResumeModule from './../ResumeModule';
import ListCategories from './../ListCategories';
import CreateFilesModal from './../CreateFilesModal';
import EditFileModal from './../EditFileModal';
import EditCategoryModal from '../EditCategoryModal';
import EditSortableCategories from '../EditSortableCategories';

import { ModuleContext } from '../../contexts/Module';

import { getToken } from '../../services/Auth';
import { requestApi } from '../../services/Request';

const ContentModule = () => {

    const token = getToken(); 
    
    const moduleContext = useContext(ModuleContext);
    
    const tempCategories = useRef([]); 
    
    useEffect(() => {        
        
        const moduleSlug = moduleContext.module.slug;
        const categories = moduleContext.module.categories;

        if(categories) {

            async function getFilesByCategory() {
    
                for (const category of categories) {
                    
                    const response = await requestApi('/api/files', 'GET', 'json', token, '', `?module=${moduleSlug}&category=${category.slug}`);
            
                    const newCategory = {
                        ...category,
                        'files': response.content.data,
                        'pagination': response.content.meta.pagination
                    };

                    tempCategories.current = [
                        ...tempCategories.current,
                        newCategory
                    ];

                    const newCategories = tempCategories.current;

                    moduleContext.saveCategories([
                        ...newCategories
                    ]);

                }

            }
    
            getFilesByCategory();

        }

        tempCategories.current = [];


    }, [moduleContext.module]);

    return (

        <main className="module">

            <MenuCategories gobackHome="true"/>

            <ResumeModule />

            {
                
                !moduleContext.isEditSortable &&
                    <ListCategories />

            }

            {
                moduleContext.module.editable &&

                    <Fragment>

                        <CreateFilesModal 
                            isOpen={ moduleContext.isCreateFilesModalOpen }
                            onRequestClose={ () => moduleContext.handleToogleCreateFilesModal(false) }
                        />

                        <EditFileModal
                            isOpen={ moduleContext.isEditFilesModalOpen }
                            onRequestClose={ () => moduleContext.handleToogleEditFilesModal(false) }
                        />

                        <EditCategoryModal
                            isOpen={ moduleContext.isEditCategoryModalOpen }
                            onRequestClose={ () => moduleContext.handleToogleEditCategoryModal(false) }
                        />

                        {
                            moduleContext.isEditSortable &&
                                <EditSortableCategories />                            
                        }

                    </Fragment>

            }

        </main>
    )

};

export default ContentModule;