import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { ModuleContext } from '../../contexts/Module';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

const MenuCategories = ({ gobackHome }) => {

    const moduleContext = useContext(ModuleContext);
    const { module } = moduleContext;
    const { categories } = module;

    let categoriesOrderByASC = categories ? [...categories] : [];

    categoriesOrderByASC?.sort((a,b) => {
        return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
    });

    const [isOpenMenuCategories, setIsOpenMenuCategories] = useState(false);

    function handleOpenMenuCategories() {
        setIsOpenMenuCategories(!isOpenMenuCategories);
    };

    return(
        
        <header className={ isOpenMenuCategories ? 'filter open' : 'filter'}>

            <div className="grid">

                <button 
                    className="filter__button filter__button--see-all" 
                    onClick={ handleOpenMenuCategories }>

                    <FontAwesomeIcon 
                        className="filter__icon" 
                        icon={ faChevronRight } />
                        
                        Todas Categorias:

                </button>

                <Link 
                    className="filter__button filter__button--return" 
                    to={ gobackHome === 'true' ? '/': `/modulo/${module.slug}`}>

                    <FontAwesomeIcon className="filter__icon" icon={ faChevronLeft } />
                    Voltar

                </Link>
                
            </div>

            <div className={ isOpenMenuCategories ? 'menu-categories open' : 'menu-categories'}>

                <div className="grid">

                    <ul className="list">

                        {

                            categoriesOrderByASC &&
                            categoriesOrderByASC.map(category => (

                                <li 
                                    className="item"
                                    key={ category.slug }>

                                    <Link
                                        className="link"
                                        to={ `/modulo/${module.slug}/categoria/${category.slug}`}>

                                        { category.name }

                                    </Link>

                                </li>

                            ))
                            
                        }

                    </ul>

                </div>

            </div>

        </header>

    )

};

export default MenuCategories;