import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getToken } from "../../services/Auth";

const Card = ({ file, module, category }) => {

    const token = getToken();
    const { name, uri, thumb, cultures, version } = file;

    const baseUrlResource = `${process.env.REACT_APP_DOMAIN}/storage`;
    const linkFileDownload = `${baseUrlResource}/${uri}?token=${token}`;

    return(

        <a 
            className="card"
            href={ linkFileDownload }
            download={ file.name } 
            target="_blank" 
            rel="noreferrer"
        >

            <span className="card__figure">
                {
                    !thumb ? (
                        <img src={`${baseUrlResource}/${module.icon}`} alt={ name }/>
                    ) : (
                        <img src={ `${baseUrlResource}/${thumb}?token=${token}` } alt={ name } />
                    )
                }
            </span>

            <span className="card__name">{ name }</span>

            <FontAwesomeIcon className="card__icon" icon={ faDownload } />

            <span className="card__version">
                {
                    version !== undefined && version !== "Única" && (
                        <span>{ version }</span>
                    )
                }
            </span>

            <span className="card__footer">
                
                <span>{ module.name } </span>

                <span>
                    
                    { category } 
                                                
                    { 
                    
                        cultures.length > 0 && (

                            cultures.length === 1 ? (
                                ` | ${cultures[0].name}`
                            ) : (

                                <div className="tooltip">

                                    <span className="tooltip__main">| Culturas</span>

                                    <span className="tooltip__text">

                                        { 
                                            cultures.map((culture, index) => {

                                                if(cultures.length === index + 1) {

                                                    return ` ${culture.name}`

                                                } else {

                                                    return ` ${culture.name}, `

                                                }
                                                
                                            })
                                        }

                                    </span>

                                </div>

                            )

                        )    

                    }

                </span>
            </span>

        </a>

    )


};

export default Card;