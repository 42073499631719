import { useState, createContext, useContext } from 'react';

const CompanyContext = createContext();

const CompanyProvider = ({ children }) => {

    const [activeCultures, setActiveCultures] = useState([]);
    const [activeProducts, setActiveProducts] = useState([]);
    const [activeCategory, setActiveCategory] = useState({});
    const [categories, setCategories] = useState([]);
    const [activeCompany, setActiveCompany] = useState({});

    const saveActiveCultures = newCultures => setActiveCultures(newCultures);
    const saveActiveProducts = newProducts => setActiveProducts(newProducts);
    const saveActiveCategory = newCategory => setActiveCategory(newCategory);
    const saveCategories = newCategory => setCategories(newCategory);
    const saveActiveCompany = company => setActiveCompany(company);


    return(

        <CompanyContext.Provider
            value={{
                activeCultures,
                activeProducts,
                activeCategory,
                categories,
                activeCompany,
                saveActiveCultures,
                saveActiveProducts,
                saveActiveCategory,
                saveCategories,
                saveActiveCompany
            }}
        >
            { children }
        </CompanyContext.Provider>

    )

};

export default CompanyProvider;

export const useCompany = () => useContext(CompanyContext);