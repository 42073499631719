import { Fragment, useContext } from 'react';

import { ModuleContext } from './../../contexts/Module';
import useLoading from '../../hooks/useLoading';

import File from './../File';

import { requestApi } from '../../services/Request';
import { showDialogError, showDialogToast } from '../../helpers/Utils';
import { getToken } from '../../services/Auth';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

const ListCategories = () => {

    const token = getToken();

    const moduleContext = useContext(ModuleContext);
    const { module } = moduleContext;
    const categories = moduleContext.categories;

    const [loader, showLoader, hideLoader] = useLoading();

    const handlePagination = (event, id) => {

        event.preventDefault();

        const index = categories.findIndex(category => category.id === id);
        const categoryCurrent = categories[index];
        const { current_page } = categoryCurrent.pagination;
        const nextPage = current_page + 1;

        showLoader(true);

        requestApi('/api/files', 'GET', 'json', token, '', `?module=${module.slug}&category=${categoryCurrent.slug}&page=${nextPage}`)
            .then(response => {

                const newCategories = categories;
                newCategories[index].files = [...newCategories[index].files, ...response.content.data];
                newCategories[index].pagination.current_page = nextPage;
                moduleContext.saveCategories([...newCategories]);

                hideLoader(false);


            })
            .catch(error => {

                showDialogError('error', error.message);
                hideLoader(false);
                
            });


    };

    const handleEditCategory = (category) => {

        moduleContext.saveCategoryEditCurrent(category);
        moduleContext.handleToogleEditCategoryModal(true); 

    };

    const handleDeleteCategory = (category) => {

        const confirm = window.confirm(`Você realmente deseja apagar a categoria: ${category.name}? Essa ação irá apagar todos os arquivos vinculados a ela!`);

        if(confirm) {

            showLoader(true);

            const data = {
                '_method': 'DELETE',
                'module': module.slug 
            };

            requestApi('/api/categories', 'POST', 'json', token, data, `/${category.slug}`)
                .then(response => {

                    moduleContext.saveUpdateModule();
                    showDialogToast('success', response.message);
                    hideLoader(false); // Fechar loader

                }).catch(error => {

                    showDialogError('error', error.message);
                    hideLoader(false); // Fechar loader

                });

        }
        
    };

    const handleEditSortableCategories = () => {

        moduleContext.handleToogleEditSortable(true);

    };

    return(
        
        <Fragment>

            {

                categories &&
                categories.length > 0 &&
                categories.map(category => (

                    <section className="categories" key={ category.id }>

                        <div className="grid">

                            <div className="title">

                                <h2 className="title__text">{ category.name }</h2>

                                {
                                    module.editable && (

                                        <div className="title__actions">

                                            <button 
                                                className="title__button"
                                                onClick={ () => handleEditSortableCategories() }>                                               
                                                    Ordernar Categorias
                                            </button>

                                            |

                                            <button 
                                                className="title__button"
                                                onClick={ () => handleEditCategory(category) }>                                                
                                                    Editar
                                            </button>

                                            |

                                            <button 
                                                className="title__button"
                                                onClick={ () => handleDeleteCategory(category) }>
                                                    Excluir
                                            </button>


                                        </div>

                                    )
                                }

                            </div>

                            <ul className="list">

                                {

                                    category.files && 
                                    category.files.map(file => (

                                        <li className="item" key={ file.id }>

                                            <File
                                                module={ module }
                                                category={ category }
                                                file={ file } />

                                        </li>

                                    ))
                                    
                                }

                            </ul>

                            {

                                category.pagination &&
                                category.pagination.total_pages > 1 &&
                                category.pagination.total_pages > category.pagination.current_page &&

                                    <button 
                                        className="button"
                                        onClick={ event => handlePagination(event, category.id)}>
                                            
                                            <span className="button__circle">

                                                <FontAwesomeIcon 
                                                    className="button__icon" 
                                                    icon={ faPlus }/>

                                            </span>

                                            Mais Arquivos

                                    </button>

                            }

                        </div>

                    </section>                

                ))

            }

            { loader }

        </Fragment>

    )

};

export default ListCategories;