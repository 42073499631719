import { Fragment, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug } from '@fortawesome/free-solid-svg-icons';

import useLoading from './../../hooks/useLoading';

import Form from './../../components/Form';
import PublicArea from './../../components/PublicArea';
import { InputText } from './../../components/Fields';
import { requestApi } from '../../services/Request';
import { showDialogToast, showDialogError } from '../../helpers/Utils';

const RedefinePassword = (props) => {

    const history = useHistory();
    const { register, handleSubmit, watch, errors } = useForm();
    const [params, setParams] = useState({});
    const [loader, showLoader, hideLoader] = useLoading();

    const submitForm = data => {
        
        showLoader(true);

        requestApi('/api/auth/reset', 'POST', 'json', '', data)
            .then(response => {

                hideLoader(false);
                showDialogToast('success', response.message);                
                setTimeout(() => { history.push('/login') }, 3500);

            })
            .catch(error => {

                hideLoader(false);
                showDialogError('error', error.message);

            });
        
    };

    useEffect(() => {

        const query = new URLSearchParams(props.location.search);
        const token = query.get('token');
        const email = query.get('email');
        setParams({ token, email });
        
    }, []);

    return(
        
        <PublicArea className="publicArea recoverPassword">
            
            <Form onSubmit={ handleSubmit(submitForm) }>

                <h1 className="form__title">Redefinir senha</h1>


                {

                    params.token !== null || params.email !== null ?

                        <Fragment>
                            <InputText
                                label="E-mail"
                                type="text"
                                name="email"
                                inputRef={ register({ required: true })}
                                error={errors.email}
                                value={ params.email }
                                filled="true"
                                />

                            <InputText
                                label="Nova senha"
                                type="password"
                                name="password"
                                inputRef={ register({ required: true })}
                                error={ errors.password }
                                autoComplete="autocomplete"
                                />

                            <InputText
                                label="Confirme a nova senha"
                                type="password"
                                name="password_confirmation"
                                inputRef={ register({ required: true, validate: value => value === watch('password') || "Senhas divergentes" })}
                                error={ errors.password_confirmation }
                                />

                            <InputText
                                label=""
                                type="hidden"
                                name="token"
                                inputRef={ register() }
                                error={ errors.token }
                                value={ params.token }
                                />

                            <button className="form__button" type="submit">Enviar</button> 
                        </Fragment>

                    :

                    <p className="form__messageFeedback form__messageFeedback--error">
                        <FontAwesomeIcon className="form__messageFeedbackIcon" icon={faBug} size="2x" />
                        A url está invalida. Verifique novamente o e-mail de recuperação de senha.<br/>
                        Se o erro persistir refaça o processo de
                        <Link to="/recuperar-senha" title="Recupera a Senha"> Recupera a Senha</Link>
                    </p>

                }
            </Form>

            { loader }

        </PublicArea>

    );

};

export default RedefinePassword;