import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ProfileContext } from '../../contexts/Profile';
import { showDialogError } from '../../helpers/Utils';
import { getToken } from '../../services/Auth';
import { requestApi } from '../../services/Request';

import { Checkbox, InputText } from '../Fields';
import Form from './../Form';

import './style.scss';

const Terms = () => {

    const profileContext = useContext(ProfileContext);
    const token = getToken();
    const { register, handleSubmit } = useForm();
    const [modalTerms, setModalTerms] = useState(true);
    const [CMSTerms, setCMSTerms] = useState();
    const [activeButtonTerms, setActiveButtonTerms] = useState(false);

    useEffect(() => {

        const { use_terms } = profileContext.profile;

        if(use_terms !== undefined) {

            setModalTerms(use_terms);

            requestApi('/api/cms/termos-de-uso', 'GET', 'JSON', token, '')
                .then(response => setCMSTerms(response.content.content))
                .catch(error => {
                    showDialogError('error', error.message);
                    modalTerms(true);
                });

        }


    }, [profileContext]);

    const submitForm = data => {

        requestApi('/api/users/optins', 'POST', 'json', token, data)
            .then(response => profileContext.saveProfile(response.content))
            .catch(error => showDialogError('error', error.message));
            
    };

    return(

        <dialog className={ modalTerms ? 'modal terms' : 'modal terms open'}>

            <div className="grid">

                <div className="content">

                    <div className="description" dangerouslySetInnerHTML={{ __html: CMSTerms }} />

                    <Form onSubmit={ handleSubmit(submitForm) }>

                        <Checkbox
                            label="Declaro que Li e aceito os Termos de Uso e Políticas"
                            type="checkbox"
                            name="use-terms"
                            inputRef={ register() }
                            onChange={ event => setActiveButtonTerms(event.target.checked) }
                            />

                        <InputText
                            label=""
                            type="hidden"
                            name="_method"
                            inputRef={ register() }
                            value="PATCH"
                            
                            />

                        {
                            activeButtonTerms ? (

                                <button className="form__button active" type="submit">Entrar</button>
                                
                                ) : (
                                    
                                <button className="form__button" type="submit" disabled>Entrar</button>

                            )
                            
                        }

                    

                    </Form>


                </div>

            </div>

        </dialog>

    )

}    

export default Terms;