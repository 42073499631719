import { useState, createContext } from 'react';

export const ModuleContext = createContext();

const ModuleProvider = ({ children }) => {

    // GLOBAL STATES
    const [module, setModule] = useState({});
    const [categories, setCategories] = useState({});
    const [updateModule, setUpdateModule] = useState(false);
    const [fileEditCurrent, setFileEditCurrent] = useState({});
    const [categoryEditCurrent, setCategoryEditCurrent] = useState({});
    const [isCreateFilesModalOpen, setIsCreateFilesModalOpen] = useState(false);
    const [isEditCategoryModalOpen, setIsEditCategoryModalOpen] = useState(false);
    const [isEditFilesModalOpen, setIsEditFilesModalOpen] = useState(false);
    const [isEditSortable, setIsEditSortable] = useState(false);

    // GLOBAL FUNCTIONS
    const saveModule = newModule => setModule(newModule);
    const saveCategories = newCategories => setCategories(newCategories);
    const saveUpdateModule = () => setUpdateModule(!updateModule);
    const saveFileEditCurrent = newFile => setFileEditCurrent(newFile);
    const saveCategoryEditCurrent = newCategory => setCategoryEditCurrent(newCategory);
    const handleToogleCreateFilesModal = state => setIsCreateFilesModalOpen(state);
    const handleToogleEditCategoryModal = state => setIsEditCategoryModalOpen(state);
    const handleToogleEditFilesModal = state => setIsEditFilesModalOpen(state);
    const handleToogleEditSortable = state => setIsEditSortable(state);

    return(

        <ModuleContext.Provider value={{
            module,
            categories,
            updateModule,
            fileEditCurrent,
            categoryEditCurrent,
            isCreateFilesModalOpen,
            isEditCategoryModalOpen,
            isEditFilesModalOpen,
            isEditSortable,
            saveModule,
            saveCategories,
            saveUpdateModule,
            saveFileEditCurrent,
            saveCategoryEditCurrent,
            handleToogleCreateFilesModal,
            handleToogleEditCategoryModal,
            handleToogleEditFilesModal,
            handleToogleEditSortable
        }}>

            { children }

        </ModuleContext.Provider>

    )

};

export default ModuleProvider;