import { useCompany } from "../../contexts/Company";

const ActiveFilter = () => {

    const { activeCultures, saveActiveCultures, activeProducts, saveActiveProducts, activeCategory, saveActiveCategory } = useCompany();

    if(activeCultures.length === 0 && activeProducts.length === 0 && !activeCategory.name) {
        return false;
    }

    const removeFilter = (item, type) => {

        if(item === 'all') {

            saveActiveCultures([]);
            saveActiveProducts([]);
            saveActiveCategory({});
            return;

        }

        if(type === 'culture') {
            const newCultures = activeCultures.filter(culture => culture.slug !== item);
            saveActiveCultures(newCultures);
        }

        if(type === 'product') {
            const newProducts = activeProducts.filter(product => product.slug !== item);
            saveActiveProducts(newProducts);
        }

        if(type === 'category') {
            saveActiveCategory({});
        }

    }

    return(
        
        <section className="activeFilter">

            <div className="activeFilter__header">
                <span>Filtros Selecionados: ({ activeCultures.length + activeProducts.length })</span>
                <button
                    onClick={() => removeFilter('all', '')}
                >
                    Limpar Filtros
                </button>
            </div>

            {
                activeCultures &&
                activeCultures.map(culture => (
                    <div className="activeFilter__line" key={ culture.slug }>
                        <span>{ culture.name }</span>
                        <button
                            onClick={() => removeFilter(culture.slug, 'culture')}
                        >
                            x
                        </button>
                    </div>
                ))
            }

            {
                activeProducts &&
                activeProducts.map(product => (
                    <div className="activeFilter__line" key={ product.slug }>
                        <span>{ product.name }</span>
                        <button
                            onClick={() => removeFilter(product.slug, 'product')}
                        >
                            x
                        </button>
                    </div>
                ))
            }

            {
                activeCategory &&
                activeCategory.name && (

                    <div className="activeFilter__line" key={ activeCategory.slug }>
                        <span>{ activeCategory.name }</span>
                        <button
                            onClick={() => removeFilter(activeCategory.slug, 'category')}
                        >
                            x
                        </button>
                    </div>
                )
                    
            }

        </section>

    )
    

};

export default ActiveFilter;