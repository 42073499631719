import ResumeModule from './../ResumeModule';
import ListCompanies from './ListCompanies';

const ContentModuleSpecial = () => {

    return(

        <main className="module">

            <ResumeModule />

            <ListCompanies />

            {/* <Money /> */}

        </main>

    )

};

export default ContentModuleSpecial;