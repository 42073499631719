import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

import { useCompany } from '../../contexts/Company';

import { showDialogError } from '../../helpers/Utils';
import { getToken } from '../../services/Auth';
import { requestApi } from '../../services/Request';

export const Products = () => {

    const { module, company } = useParams();
    const token = getToken();

    const [products, setProducts] = useState([]);
    
    const { activeProducts, saveActiveProducts} = useCompany();

    const [isActiveListProduct, setIsActiveListProduct] = useState(false);

    useEffect(() => {

        requestApi('/api/products', 'GET', 'json', token, '', `?module=${module}&company=${company}&has-files=true`)
            .then(response => setProducts(response.content.data))
            .catch(error => showDialogError('error', error.message))

    }, [module]);

    const changeProducts = event => {

        if (event.target.checked) {

            saveActiveProducts([...activeProducts, {
                slug: event.target.name,
                name: event.target.value
            }]);

        } else {

            const newProducts = activeProducts.filter(product => product.slug !== event.target.name);
            saveActiveProducts([...newProducts]);

        }

    };

    if(products.length === 0) {
        return null;
    }


    return(

        <section className={ isActiveListProduct ? 'products active': 'products' }>

            <button onClick={ () => setIsActiveListProduct(!isActiveListProduct) }>
                
                Produtos

                <FontAwesomeIcon icon={ !isActiveListProduct ? faPlus : faMinus } />
            </button>

            <ul>

                { 
                
                    products && 
                    products.map(product => (

                        <li key={ product.slug }>

                            <label htmlFor={ product.slug }>
                                <input 
                                    type="checkbox" 
                                    name={ product.slug } 
                                    id={ product.slug }
                                    value={ product.name }
                                    onChange={ changeProducts }
                                    // checked={ activeProducts.includes(product.slug) }
                                    checked={ activeProducts.findIndex(element => element.slug === product.slug) >= 0 ? true : false } 
                                    />

                                { product.name }

                            </label>

                        </li>

                    ))    

                }

            </ul>

        </section>


    )

};

export default Products;