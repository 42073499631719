import './style.scss';

const Form = (props) => {

    const { children, onSubmit, foRef, encType } = props;

    return(
        <form className="form" onSubmit={onSubmit} ref={foRef} encType={encType}>
            { children }
        </form>
    );

};

export default Form;