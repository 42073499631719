import { toast } from 'react-toastify';

export const showDialogError = (type, message, options = { position: 'top-right', autoClose: 3000, hideProgressBar: false }) => {

    const newOptions = { ...options, type };
    const messages = JSON.parse(message);
    
    messages.forEach(item => {
        Object.values(item).forEach(text => {
            toast(...text, newOptions);
        })
    })

}

export const showDialogToast = (type, message, options = { position: 'top-right', autoClose: 3000, hideProgressBar: false }) => {
    
    const newOptions = { ...options, type };

    toast(message, newOptions);

};

export const prepareFormData = (formData, adds, removes) => {

    removes.forEach(field => formData.delete(field));

    adds.forEach(field => formData.append(field.name, field.value));
    
    return formData;

}