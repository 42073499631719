import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { useCompany } from "../../contexts/Company";
import useLoading from "../../hooks/useLoading";

import Card from "./Card";

import { showDialogError } from "../../helpers/Utils";
import { getToken } from "../../services/Auth";
import { requestApi } from "../../services/Request";

import "./style.scss";

const ResultsCompany = () => {

    const { module, company } = useParams();
    
    const token = getToken();
    
    const per_page = 3;
    const [currentPage, setCurrentPage] = useState(1); 
    const [viewMoreButton, setViewMoreButton] = useState(true);

    const [categories, setCategories] = useState([]);
    const [partialsCategories, setPartialsCategories] = useState([]);
    const { activeCultures, activeProducts, activeCategory } = useCompany();

    const [loader, showLoader, hideLoader] = useLoading();

    useEffect(() => {

        const filters = mountFilter();

        showLoader(true);

        requestApi('/api/files', 'GET', 'json', token, '', `${filters}`, '')
            .then(response => {
                
                setCurrentPage(1);
                setCategories(prepareObj(response.content.data))
                setPartialsCategories(prepareObj(response.content.data).splice(0, per_page * currentPage));
                hideLoader(false);

            })
            .catch(error => {

                hideLoader(false);
                showDialogError('error', error.message);
                
            });

    }, [activeCultures, activeProducts, activeCategory]);

    useEffect(() => {

        const total_pages = Math.ceil(categories.length / per_page);

        if(currentPage < total_pages) {

            setViewMoreButton(true);

        } else {

            setViewMoreButton(false);

        }
        

    }, [partialsCategories])

    const mountFilter = () => {

        let category = Object.values(activeCategory).length > 0 ? `&category=${activeCategory.slug}` : '';
        
        let cultures = activeCultures.length > 0 ? activeCultures.reduce((accumulator, current) => {
            accumulator += `&culture[]=${current.slug}`;
            return accumulator;
        }, '') : "";

        let products = activeProducts.length > 0 ? activeProducts.reduce((accumulator, current) => {
            accumulator += `&product[]=${current.slug}`;
            return accumulator;
        }, '') : "";

        return `?module=${module}${category}${cultures}${products}&company=${company}&per_page=all`;

    };

    const prepareObj = data => {

        const newObj = data.reduce((accumulator, current) => {
            
            let isFound = accumulator.find(element => element.category.slug === current.category.slug);

            if(isFound) {
                isFound.files = [
                    ...isFound.files,
                    {
                        id: current.id,
                        name: current.name,
                        order: current.order,
                        thumb: current.thumb,
                        uri: current.uri,
                        cultures: current.cultures, 
                        version: current.version.name
                    }
                ];
                return accumulator;
            }

            return [
                ...accumulator,
                {
                    module: {
                        name: current.module.short_title,
                        icon: current.module.style.files.normal
                    },                    
                    category: {
                        id: current.category.id,
                        order: current.category.order,
                        name: current.category.name,
                        slug: current.category.slug
                    },
                    files: [
                        {
                            id: current.id,
                            name: current.name,
                            order: current.order,
                            thumb: current.thumb,
                            uri: current.uri,
                            cultures: current.cultures, 
                            version: current.version.name,
                        }
                    ]
                }
            ];

        }, []);

        return newObj;

    };

    const handlePaginationViewMore = () => {

        const tempCategories = [...categories];        

        setPartialsCategories(tempCategories.splice(0, per_page * (currentPage + 1)));
        setCurrentPage(currentPage+1);

    };

    return(

        <Fragment>
        
            <div className="results">

            {
                categories.length > 0 ? (

                    <Fragment>

                        {
                            partialsCategories.map(element => (

                                <div className="category" key={ element.category.slug }>
            
                                    <h2>{ element.category.name }</h2>
            
                                    <ul className="list">
            
                                    {
                                        element.files.map(file => (
            
                                            <li key={file.uri}>
            
                                                <Card 
                                                    file={file} 
                                                    module={element.module} 
                                                    category={element.category.name}
                                                />
            
                                            </li>
            
                                        ))
                                    }                                
            
                                    </ul>                            
            
                                </div>
            
                            ))
                        }

                        {

                            viewMoreButton && (
                                <button
                                    className="button"
                                    onClick={() => handlePaginationViewMore()}
                                >
                                    <span className="button__circle">

                                    <FontAwesomeIcon 
                                        className="button__icon" 
                                        icon={ faPlus }/>

                                    </span>

                                    Mais Arquivos
                                </button>
                            )

                        }
                        
                    </Fragment>

                ) : (

                    <p>Sua busca não retornou nenhum arquivo!</p>

                )
                
            }

        </div>
        
            { loader }

        </Fragment>

    )

};

export default ResultsCompany;