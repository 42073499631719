import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import useLoading from './../../hooks/useLoading';

import Form from './../../components/Form';
import PublicArea from './../../components/PublicArea';

import { showDialogError } from './../../helpers/Utils'; 
import { requestApi } from '../../services/Request';
import { InputText, Checkbox } from '../../components/Fields';
import { setToken } from './../../services/Auth';

const Login = () => {

    const history = useHistory();
    const { register, handleSubmit, errors } = useForm();
    const [loader, showLoader, hideLoader] = useLoading();

    const submitForm = data => {

        showLoader(true);

        requestApi('/api/auth/login', 'POST', 'json', '', data)
            .then(response => {

                hideLoader(false);
                setToken(response.content.access_token);
                history.push('/');
                
            })
            .catch(error => {

                hideLoader(false);
                showDialogError('error', error.message);
                
            });
            
    }

    return(

        <PublicArea className="publicArea login">

            <Form onSubmit={ handleSubmit(submitForm) }>

                <InputText
                    label="E-mail"
                    type="email"
                    name="email"
                    inputRef={ register({ required: true}) }
                    error={ errors.email }
                    filled="true"
                />

                <InputText
                    label="Senha"
                    type="password"
                    name="password"
                    inputRef={ register({ required: true}) }
                    error={errors.password}
                    filled="true"
                />

                <Checkbox
                    label="Lembrar sempre"
                    type="checkbox"
                    name="remember"
                    inputRef={ register() }
                    />

                <button className="form__button" type="submit">Entrar</button>

            </Form>
            
            <footer className="footer">

                <p className="footer__text">

                    <Link 
                        className="footer__link" 
                        to="/recuperar-senha" 
                        title="Esqueceu a senha?">
                            Esqueceu a senha?
                    </Link>

                </p>

                <p className="footer__text">
                    Ainda não tem o acesso? 

                    <Link 
                        className="footer__link"
                        to="/cadastro"
                        title="Cadastre-se">
                            Cadastre-se
                    </Link>

                </p>
            </footer>

            { loader }
        
        </PublicArea>

    );

};

export default Login;