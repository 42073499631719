import { useContext } from 'react';

import { ModuleContext } from './../../contexts/Module';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

const ResumeModule = () => {

    const moduleContext = useContext(ModuleContext);
    const { module } = moduleContext;

    return(

        <section className="resume-module">

            <div className="grid">

                <div className="information">

                    {
                        (module.editable && module.special !== true) && (

                            <button 
                                className="button"
                                style={{ color: module?.style?.color }}
                                onClick={ () => moduleContext.handleToogleCreateFilesModal(true) }>

                                    <FontAwesomeIcon 
                                        className="button__icon" 
                                        icon={ faFileUpload } 
                                        size="2x" />
                                        
                                    Enviar Arquivo
                                    
                            </button>

                        )
                    }

                    <h1 className="information__title">
                        { module.title }
                    </h1>

                    <div 
                        className="information__description"
                        dangerouslySetInnerHTML={{ __html: module.description }}/>

                </div>

                <figure
                    className="figure"
                    style={{ backgroundColor: module?.style?.color }}
                >
                    <img
                        className="figure__image"
                        src={ `${process.env.REACT_APP_DOMAIN}/storage/${module.image}` }
                        alt={ module.title }/>
                        
                    <figcaption className="figure__title">
                        { module.short_title }
                    </figcaption>

                </figure>

            </div>

        </section>

    )

};

export default ResumeModule;