import { useContext } from 'react';
import { ProfileContext } from './../../contexts/Profile';

import './style.scss';
import image from './../../assets/images/introducao-imagem.png';

const Summary = () => {    

    const profileContext = useContext(ProfileContext);
    const { name, avatar } = profileContext.profile; 

    return(
    
        <section className="summary">

            <div className="grid">

                <div className="information">

                    <figure className="information__avatar">

                        <img className="" src={ `${process.env.REACT_APP_DOMAIN}/storage/${avatar}` } alt="Foto de Perfil" />

                    </figure>

                    <h1 className="information__title">
                        Olá <span>{ name },</span>
                        <small>Seja bem-vindo(a)</small>
                    </h1>
                    
                    <h2 className="information__subtitle">
                        Em nossa plataforma você encontra todos os arquivos e documentos que são disponibilizados
                        pelos departamentos.
                    </h2>

                </div>

                <figure className="figure">

                    <img className="figure__image" src={ image } alt="Aqui você encontra..." />

                </figure>

            </div>

        </section>

    );

};

export default Summary;