import React, { useState } from 'react';
import Loading from './../components/Loading';

const useLoading = (percent) => {    

    const [loading, setLoading] = useState(false);

    return [

        loading ? <Loading percent={ percent } /> : null,
        () => setLoading(true),
        () => setLoading(false)

    ]

};

export default useLoading;